<template>
  <div id="web" > 
    <div v-if="loading" class="row justify-content-center">
        <img class="spinner-logo" src="../../public/logo-spinner.svg" alt="kartek logo de pagina cargando"  />
        <!--<font-awesome-icon icon="car" class="mr-2 spinner" spin  />-->
    </div> 
    <div v-else >
        <NavBar/>
        
        <ToTop/>
        <div id="arriba">
            <div  class="d-none d-lg-block" >
                <b-carousel
                    class="carousel-init"
                    :interval="10000"
                    controls
                    indicators
                    background="#fff"
                    aria-label="Carrusel de images del coche"
                    >
                    <b-carousel-slide >
                        <template #img>
                            <v-lazy-image class="img-init" alt="coche cotxe alta gama - rs5 verde 2022 "
                                src="../car/rs5_full.webp"
                                src-placeholder="../car/rs5_min.webp"
                            />
                            <div class="text-block-rs5">
                                <h1 class="init-text">{{ $t('title_carousel_rs5')}}</h1>
                                <h1 class="init-subtext">{{ $t('subtitle_carousel_rs5')}}</h1>
                            </div>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide >
                        <template #img>
                            <v-lazy-image class="img-init"  alt="coche cotxe gama media - volswagen golf gti" 
                                src="../car/golf_full.webp"
                                src-placeholder="../car/golf_min.webp"
                            />
                            <div class="text-block-golf">
                                <h1 class="init-text">{{ $t('title_carousel_golf')}}</h1>
                                <h1 class="init-subtext">{{ $t('subtitle_carousel_golf')}}</h1>
                            </div>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide >
                        <template #img>
                            <v-lazy-image class="img-init" alt="coche cotxe alta gama - rs5 verde 2022 "
                                src="../car/allroad_1_full.webp"
                                src-placeholder="../car/allroad_1_min.webp"
                            />
                            <!--<vue-load-image>
                                <img slot="image" class="img-init" src="../../public/car/car_10.webp" alt="coche cotxe alta gama - rs5 2021 450cv" loading="lazy">
                                <img slot="preloader" class="spinner-logo" src="../../public/logo-spinner.svg"/>
                                <div slot="error">Error en carregar, recarregueu la pàgina</div>
                            </vue-load-image>-->
                            <div class="text-block3">
                                <h1 class="init-text">{{ $t('title_carousel_all_blue')}}</h1>
                                <h1 class="init-subtext">{{ $t('subtitle_carousel_all_blue')}}</h1>
                            </div>
                        </template>
                    </b-carousel-slide>
                    
                    <b-carousel-slide >
                        <template #img>
                            <v-lazy-image class="img-init"  alt="camper alta gama - furgoneta sprinter camper 4x4" 
                                src="../car/sprinter_full.webp"
                                src-placeholder="../car/sprinter_min.webp"
                            />
                            <div class="text-block4">
                                <h1 class="init-text">{{ $t('title_carousel_explorer')}}</h1>
                                <h1 class="init-subtext">{{ $t('subtitle_carousel_explorer')}}</h1>
                            </div>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide >
                        <template #img>
                            <v-lazy-image class="img-init" alt="coche cotxe alta gama - rs5 verde 2022 "
                                src="../car/allroad_2_full.webp"
                                src-placeholder="../car/allroad_2_min.webp"
                            />
                            <div class="text-block5">
                                <h1 class="init-text">{{ $t('title_carousel_all_white')}}</h1>
                                <h1 class="init-subtext">{{ $t('subtitle_carousel_all_white')}}</h1>
                            </div>
                        </template>
                    </b-carousel-slide>
                </b-carousel>   
            </div>

            <div class="d-lg-none" >
                <b-carousel
                class="carousel-init"
                    :interval="10000"
                    indicators
                    background="#fff"
                    aria-label="Carrusel de images del coche"
                    >
                    <b-carousel-slide >
                        <template #img>
                            <v-lazy-image class="img-init-phone" alt="coche cotxe alta gama - rs5 verde 2022 "
                                src="../car/rs5_full.webp"
                                src-placeholder="../car/rs5_min.webp"
                            />     
                            <div class="text-block-phone">
                                <h1 class="init-text-phone">{{ $t('title_carousel_rs5')}}</h1>
                            </div>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide >
                        <template #img>
                            <v-lazy-image class="img-init-phone"  alt="coche cotxe gama media - volswagen golf gti" 
                                src="../car/golf_full.webp"
                                src-placeholder="../car/golf_min.webp"
                            />
                            <div class="text-block-phone2">
                                <h1 class="init-text-phone">{{ $t('title_carousel_golf')}}</h1>
                            </div>
                        </template>
                </b-carousel-slide>
                    <b-carousel-slide >
                        <template #img>
                            <v-lazy-image class="img-init-phone"  alt="coche cotxe alta gama - todoterreno ford explorer hibrido enchufable" 
                                src="../car/allroad_1_full.webp"
                                src-placeholder="../car/allroad_1_min.webp"
                            />
                            <div class="text-block-phone3">
                                <h1 class="init-text-phone">{{ $t('title_carousel_all_blue')}}</h1>
                                 <!--<h1 >{{ $t('subtitle_carousel_1')}}</h1>-->
                            </div>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide >
                        <template #img>
                            <v-lazy-image class="img-init-phone"  alt="coche cotxe alta gama - todoterreno ford explorer hibrido enchufable" 
                                src="../car/sprinter_full.webp"
                                src-placeholder="../car/sprinter_min.webp"
                            />
                            <div class="text-block-phone4">
                                <h1 class="init-text-phone">{{ $t('title_carousel_explorer')}}</h1>
                                 <!--<h1 >{{ $t('subtitle_carousel_1')}}</h1>-->
                            </div>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide >
                        <template #img>
                            <v-lazy-image class="img-init-phone"  alt="coche cotxe alta gama - todoterreno ford explorer hibrido enchufable" 
                                src="../car/allroad_2_full.webp"
                                src-placeholder="../car/allroad_2_min.webp"
                            />                       
                            <div class="text-block-phone2">
                                <h1 class="init-text-phone2">{{ $t('title_carousel_all_white')}}</h1>
                                 <!--<h1 >{{ $t('subtitle_carousel_1')}}</h1>-->
                            </div>
                        </template>
                    </b-carousel-slide>                   
                   
                </b-carousel>
            </div>
            <div id="stock"></div>
            <div class="section-web">
                <div class="row justify-content-center">
                    <img src="../../public/decorator-phone1.svg" alt=""   class="d-lg-none phone-decorator">
                    <img src="../../public/title-decorator1.svg" alt=""   class="d-none d-lg-block text-decorator1 mt-3">
                    <h1  class="title-main mx-3">{{ $t('Stock')}}</h1>
                    <img src="../../public/decorator-phone2.svg" alt=""   class="d-lg-none phone-decorator">
                    <img src="../../public/title-decorator2.svg" alt=""  class="d-none d-lg-block text-decorator2 mt-3">

                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-md-11 col-sm-12 align">
                        <p class="h3">{{ $t('stock_text_1')}}</p>
                    </div>
                </div>
                <div v-if="loading_cars==true" class="row justify-content-center">
                    <img class="spinner-cars" src="../../public/logo-spinner.svg" alt="loading cars stock" />
                    <div class="col-12 text-center">
                        <p class="h3" v-cloak>{{ $t('Cargando')}}...</p>
                    </div>
                </div>        
                <div class="row justify-content-center" v-else >
                    <div class="col-lg-3 col-md-8 col-sm-12 text-center mt-4 m-1" v-for="car in cars" :key="car.name">
                        <div class="card card-shadow black car-card">
                            <div v-if="(car.imgs.min_imgs.front).length==0 && (car.imgs.min_imgs.others).length==0" class="carousel-init mt-0">
                                <img src="../../public/logo.svg" class="card-img-top p-2" alt="coche">
                            </div>          
                            <b-carousel v-else                        
                                :interval="0"
                                controls                            
                                background="#fff"
                                >
                                <b-carousel-slide v-for="img in car.imgs.min_imgs.front" :key="img.name">
                                    <template #img>
                                        <vue-load-image>
                                            <img slot="image" class="card-img-top" :src="img.url"  alt="foto principal del coche" loading="lazy">
                                            <img slot="preloader" class="card-img-spinner" src="../../public/logo-spinner.svg"/>
                                            <div slot="error">Error en carregar, recarregueu la pàgina</div>
                                        </vue-load-image>
                                    </template>
                                </b-carousel-slide>
                                <b-carousel-slide v-for="img in car.imgs.min_imgs.others" :key="img.name">
                                    <template #img>
                                        <vue-load-image>
                                            <img slot="image" class="card-img-top" :src="img.url" alt="foto de carrousel del coche" loading="lazy">
                                            <img slot="preloader" class="card-img-spinner" src="../../public/logo-spinner.svg"/>
                                            <div slot="error">Error en carregar, recarregueu la pàgina</div>
                                        </vue-load-image>
                                        <!--<img class="card-img-top" :src="img.url" alt="foto de carrousel del coche">-->
                                    </template>
                                </b-carousel-slide>
                            </b-carousel>                                 
                            
                            <router-link   :to="{name: 'car', params:{car_id:car.id}}" class="card-body text-center black">  
                                <div v-if="car.is_reserved==true" class="card-img-overlay overlay-extra extra-img">
                                    <p class="overlay-text">{{ $t('reserved')}}</p>                       
                                </div>                        
                                <p class="h2 bold">{{car.brand}} {{car.model}} {{car.engine}}</p>
                                <p class="h4">{{car.kilometers}}</p>
                                <p class="h2 blue mb-4">{{car.price}}</p>
                                <div class="row justify-content-center">
                                    <p class="icons-car">
                                        <font-awesome-icon v-if="car.fuel.es=='Gasolina' || car.fuel.es=='Híbrido' || car.fuel.es=='Gasoil'" icon="gas-pump" class="icon-car" />
                                        <font-awesome-icon v-if="car.fuel.es=='Eléctrico' || car.fuel.es=='Híbrido'" icon="bolt" class="icon-car"/><br>
                                        {{car.fuel[$i18n.locale]}}
                                    </p>
                                    <p class="icons-car">
                                        <img src="../../public/gearbox.svg" class="icon-car2" alt="gearbox"><br> {{car.gearbox[$i18n.locale]}}
                                    </p>
                                    <p class="icons-car">
                                        <img src="../../public/horsepower.svg" class="icon-car2" alt="gearbox"><br> {{car.horsepower}} 
                                        <span v-if="[$i18n.locale]=='en'">hp</span><span v-else-if="[$i18n.locale]=='fr'">up</span><span v-else>cv</span>
                                    </p>
                                    <p  v-b-popover.hover.top="'Año'" class="icons-car">
                                        <font-awesome-icon icon="calendar" class="icon-car" /><br>
                                        {{car.year}}
                                    </p>   
                                    <p  v-if="car.has_nft==true && [$i18n.locale]=='es'"  v-b-popover.hover.top="'Disponible para comprar con criptomonedas'">
                                        <font-awesome-icon :icon="['fab', 'bitcoin']" class="nav-bitcoin" />
                                    </p>                 
                                    <p  v-if="car.has_nft==true && [$i18n.locale]=='en'"  v-b-popover.hover.top="'Available to buy with cryptocurrencies'">
                                        <font-awesome-icon :icon="['fab', 'bitcoin']" class="nav-bitcoin" />
                                    </p>               
                                    <p  v-if="car.has_nft==true && [$i18n.locale]=='fr'"  v-b-popover.hover.top="'Disponible à l`achat avec des crypto-monnaies'">
                                        <font-awesome-icon :icon="['fab', 'bitcoin']" class="nav-bitcoin" />
                                    </p>  
                                    <p  v-if="car.has_nft==true && [$i18n.locale]=='cat'"  v-b-popover.hover.top="'Disponible per comprar amb criptomonedes'">
                                        <font-awesome-icon :icon="['fab', 'bitcoin']" class="nav-bitcoin" />
                                    </p>       
                                                            
                                </div>                    
                               <div class="container2">
                                    <a class="buttn">
                                        <div class="button__line"></div>
                                        <div class="button__line"></div>
                                        <span class="button__text">+ info<font-awesome-icon icon="arrow-right" class="ml-3 d-none d-md-block" /></span>                                
                                    </a>
                                </div>
                                <!--<h4 class="bold blue">+ info</h4>-->
                            </router-link>
                        </div>
                    </div>            
                </div>   
            </div>        
            <div id="sold mb-4"></div>
            <div class="back section-web">
                <div class="row justify-content-center ">
                    <h1  class="title-main">{{ $t('Coches a la carta')}}</h1>
                </div>
                <div class="row justify-content-center"  >
                    <div class="col-lg-10 col-md-11 col-sm-12 align" >
                        <p class="h3">{{ $t('carta_text_1')}}</p>
                        <p class="h3" >{{ $t('carta_text_2')}}</p>
                        <p class="h3" v-if="sold_cars.length!=0" >{{ $t('carta_text_3')}}</p>
                    </div>
                </div>
                <div v-if="loading_cars==true && sold_cars.length!=0" class="row justify-content-center" v-cloak>
                    <img class="spinner-cars" src="../../public/logo-spinner.svg" alt="cargando coches vendidos"  />
                    <div class="col-12 text-center">
                        <p class="h3" >{{ $t('Cargando')}}...</p>
                    </div>
                </div>
                <div class="row justify-content-center" v-else>
                    <div class="col-lg-3 col-md-8 col-sm-12 text-center mt-4 m-1" v-for="car in sold_cars" :key="car.name">
                        <div class="card card-shadow black car-card ">
                            <div v-if="(car.imgs.min_imgs.front).length==0" class="carousel-init mt-0">
                                <img src="../../public/logo.svg" class="card-img-top p-2" alt="logo kartek">
                            </div>
                            <b-carousel    v-else
                                :interval="0"
                                controls
                                background="#fff"
                                >
                                <b-carousel-slide v-for="img in car.imgs.min_imgs.front" :key="img.name">
                                    <template #img>
                                        <vue-load-image>
                                            <img slot="image" class="card-img-top" :src="img.url" alt="foto de principal del coche" loading="lazy">
                                            <img slot="preloader" class="card-img-spinner" src="../../public/logo-spinner.svg"/>
                                            <div slot="error">Error en carregar, recarregueu la pàgina</div>
                                        </vue-load-image>
                                    </template>
                                </b-carousel-slide>
                                <b-carousel-slide v-for="img in car.imgs.min_imgs.others" :key="img.name">
                                    <template #img>
                                        <vue-load-image>
                                            <img slot="image" class="card-img-top" :src="img.url" alt="foto de carrousel del coche" loading="lazy">
                                            <img slot="preloader" class="card-img-spinner" src="../../public/logo-spinner.svg"/>
                                            <div slot="error">Error en carregar, recarregueu la pàgina</div>
                                        </vue-load-image>
                                    </template>
                                </b-carousel-slide>
                            </b-carousel>          

                            <router-link  :to="{name: 'car_sold', params:{car_id:car.id}}" class="card-body text-center black">                                                    
                                <p class="bold h2">{{car.brand}} {{car.model}} {{car.engine}}</p>
                                <p class="h4">{{car.kilometers}} Km</p>
                                <div class="row justify-content-center">
                                    <p class="icons-car">
                                        <font-awesome-icon v-if="car.fuel.es=='Gasolina' || car.fuel.es=='Híbrido' || car.fuel.es=='Gasoil'" icon="gas-pump" class="icon-car" />
                                        <font-awesome-icon v-if="car.fuel.es=='Eléctrico' || car.fuel.es=='Híbrido'" icon="bolt" class="icon-car"/><br>
                                        {{car.fuel[$i18n.locale]}}
                                    </p>
                                    <p class="icons-car">
                                        <img src="../../public/gearbox.svg" class="icon-car2" alt="gearbox"><br> {{car.gearbox[$i18n.locale]}}
                                    </p>
                                    <p class="icons-car">
                                        <img src="../../public/horsepower.svg" class="icon-car2" alt="gearbox"><br> {{car.horsepower}} 
                                        <span v-if="[$i18n.locale]=='en'">hp</span><span v-else-if="[$i18n.locale]=='fr'">up</span><span v-else>cv</span>
                                    </p>
                                    <p  v-b-popover.hover.top="'Año'" class="icons-car">
                                        <font-awesome-icon icon="calendar" class="icon-car" /><br>
                                        {{car.year}}
                                    </p>   
                                </div>               
                                
                                <h4 class="blue mt-2">+ INFO</h4>
                            </router-link>
                        </div>
                    </div>
                </div>     
            </div>      
            <div  id="we-are"></div>
            <div class=" mb-4" >
                    <div class="row justify-content-center">            
                        <img src="../../public/decorator-phone1.svg" alt=""   class="d-lg-none phone-decorator">
                        <img src="../../public/title-decorator1.svg" alt=""   class="d-none d-lg-block text-decorator1 mt-3">
                        <h1  class="title-main mx-3">{{ $t('Nosotros')}}</h1>
                        <img src="../../public/decorator-phone2.svg" alt=""   class="d-lg-none phone-decorator">
                        <img src="../../public/title-decorator2.svg" alt=""  class="d-none d-lg-block text-decorator2 mt-3">
                    </div>      
                    <div class="row justify-content-center">         
                        <div class="col-lg-10 col-md-11 col-sm-12 align"><!--Especialistas en gestión de importación, exportación y logística. -->
                            <p class="h3">{{ $t('nosotros_text_1')}}
                                <!--Somos un equipo joven que emprendemos este negocio con intención de ofrecer algo distinto en el mercado de automóviles de Andorra. Tras muchos kilometros al volante somos conscientes de que ciertos detalles pueden cambiar por completo la experiencia del día a día con su vehículo, y a quienes les gusta o necesitan pasar tiempo en al volante como nosotros saben valorarlo. Por eso cada vehículo es minuciosamente seleccionado, prestando mucha atención al detalle y al conjunto de extras, para ofrecer lo máximo que cada modelo puede dar. -->
                            </p>
                            <p class="h3"> 
                                {{ $t('nosotros_text_2')}}
                                <a href="https://detailpark.com/todo-lo-que-debes-saber-sobre-el-detailing/" class="blue"  target="_blank" rel="noopener" title="Enlace a todo lo que debes saber sobre el detailing"> detailing </a>
                                {{ $t('nosotros_text_3')}}
                                <!--Nuestros precios son ajustados para poder ofrecer el vehículo más equilibrado en precio/prestaciones, dificilmente encontrará en Andorra coches más completos que los que nosotros ofrecemos. Además, todos nuestros vehículos son puestos a punto con mimo con una sesión de "detailing" antes de pasar a las manos de comprador. Buscamos la satisfacción del cliente con cada opción que presentamos, y cuando sea necesario, aconsejar en la selección de vehículos  a la carta basandonos en ese criterio.-->
                            </p>
                        </div>
                    </div>
               
            </div>
                <div  id="crypto"></div>
                <div class="back-cripto section-web">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h1  class="title-main">{{ $t('Pagos en criptomonedas')}}</h1>
                        </div>
                        <div class="col-lg-10 col-md-11 col-sm-12 align">
                            <p class="h3">{{ $t('crypto_text_1')}} </p>
                            <p class="h3">{{ $t('crypto_text_2')}}
                            </p>
                        </div>
                        <div class="col-lg-10 col-md-11 col-sm-12 align">
                            <b-collapse id="collapse-1" >
                            <p style="border:1px solid lightgrey; margin-top:20px"></p>
                            <p class="h3">{{ $t('crypto_text_3')}} </p>
                            <p class="h3">{{ $t('crypto_text_4')}} </p>
                            <p class="h3">{{ $t('crypto_text_5')}} </p>
                            <p class="h3" >{{ $t('contact_text')}}</p>
                            </b-collapse>
                            <a v-b-toggle.collapse-1  class="h4 blue">
                                <p class="text-center blue">{{ $t('Más información')}} <font-awesome-icon icon="chevron-down" class="ml-2"/><font-awesome-icon icon="chevron-up" class="ml-2"/></p>                                         
                            </a>
                        </div>
                        <div class="col-lg-12 col-md-11 col-sm-12 text-center " style="margin-top:50px">
                            <a href="https://bitcoin.org/es/"><img src="../../public/coins/btc.svg" class="coin"  target="_blank" rel="noopener" title="más información sobre bitcoin" alt="más información sobre bitcoin"></a> 
                        
                            <a href="https://ethereum.org/es/" target="_blank" rel="noopener" title="más información sobre ethereum" alt="más información sobre ethereum"><img src="../../public/coins/eth.svg" class="coin" alt="eth icon"></a> 
                            <a href="https://www.circle.com/en/usdc" target="_blank" rel="noopener" title="más información sobre USDC" alt="más información sobre USDC"><img src="../../public/coins/usdc.svg" class="coin" alt="usdc icon"></a> 
                            <a href="https://abracadabra.money/" target="_blank" rel="noopener" title="más información sobre MIM" alt="más información sobre MIM"><img src="../../public/coins/mim.png" class="coin" alt="mim icon"></a>  
                            <a href="https://tether.to/en/" target="_blank" rel="noopener" title="más información sobre tether" alt="más información sobre tether"><img src="../../public/coins/usdt.svg" class="coin" alt="usdt icon"></a> 
                            <a href="https://makerdao.com/en/" target="_blank" rel="noopener" title="más información sobre DAI" alt="más información sobre DAI"><img src="../../public/coins/dai.svg" class="coin" alt="dai icon"></a> 
                            <a href="https://fantom.foundation/" target="_blank" rel="noopener" title="más información sobre fantom" alt="más información sobre fantom"><img src="../../public/coins/ftm.svg" class="coin" alt="ftm icon"></a>     
                        </div>      
                        <!--<div class="col-lg-10 col-md-11 col-sm-12 text-center mt-4">
                            <router-link :to="{name: 'my_cars'}" class="bttn btn-btc font-big">
                                {{ $t('Acceder a mis coches')}}
                            </router-link>
                        </div>-->
                        <div class="col-lg-10 col-md-11 col-sm-12 text-center mt-4">
                            <div class="container">
                                <router-link :to="{name: 'my_cars'}" class="buttn">
                                    <div class="button__line"></div>
                                    <div class="button__line"></div>
                                    <span class="button__text">{{ $t('Acceder a mis coches')}} <font-awesome-icon icon="arrow-right" class="ml-3 d-none d-md-block" /></span>
                                
                                </router-link>
                            </div>
                        </div>

                    </div>
                </div>
            
            <div  id="contact"></div>
            <div class="row justify-content-center">
               <img src="../../public/decorator-phone1.svg" alt=""  class="phone-decorator">
                 <!--<img src="../../public/title-decorator1.svg" alt=""   class="d-none d-lg-block text-decorator1 mt-3">-->
                <h1  class="title-main mx-3">{{ $t('Contacto')}}</h1>
                <img src="../../public/decorator-phone2.svg" alt=""  class="phone-decorator">
                 <!--<img src="../../public/title-decorator2.svg" alt=""  class="d-none d-lg-block text-decorator2 mt-3">-->
            </div>
            <div class="row justify-content-center mt-4" style="margin-bottom:60px">
                  <div class="col-lg-10 col-md-11 col-sm-12 text-center mb-4">
                      <p class="h3">{{ $t('contact_text_1')}}</p>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                    <a  title="Envíanos un mesaje por whatsapp" target="_blank" href="https://wa.me/376395375" ><font-awesome-icon :icon="['fab', 'whatsapp-square']" class="whatsapp" /></a>
                    <p class="h3">{{ $t('contact_text_2')}}</p>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                      <font-awesome-icon icon="envelope" class="mb-2 icon" />
                      <p class="h3">info@kartek.ad</p>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                      <font-awesome-icon icon="phone" class="mb-2 icon" />
                      <p class="h3">+376 395 375 </p>
                  </div>
                  
              </div>
            <div id="recaptcha_div" data-size="invisible"></div>
            
            <div class="row justify-content-center mb-4" id="contact">
                <div class="col-lg-6 col-md-10 col-sm-12 text-center card card-shadow p-0" >
                    <div class="contact-phone card-body" style="margin-top: 30px">
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-1 text-left ">
                                    <label class="form-label" for="nombre">{{ $t('Nombre')}}</label>
                                    <input class="form-input" type="text" name="nombre" id="nombre" v-model="nombre">
                            </div>
                            <div class="col-lg-6 col-md-12 mb-1 text-left">
                                <label class="form-label" for="email">{{ $t('Correo electrónico')}}</label>
                                <input  class="form-input" type="text" name="email" id="email" v-model="email">
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-lg-12 col-md-12 text-left">
                                <div class="form-group ">
                                    <label class="form-label" for="message">{{ $t('Mensaje')}}</label>
                                    <textarea v-model="message" type="text" class="form-input" name="message" id="message" cols="30" rows="10"></textarea>
                                    <!--{{ form.message(class="form-input", rows="6", id="mensaje-contact") }}-->
                                </div>
                            </div>
                        </div>
                        <div class="row" >
                            <div id="privacy" class="col-11 jus">
                                <div class=" text-center"><input v-model="privacy" type="checkbox" class="checkbox"> 
                                    <span class="h5">{{ $t('politica')}}</span>
                                </div>
                                <!--{{form.checkbox(class="", type="checkbox", style="width:18px; height:18px", id="checkbox-contact")}}
                                    <span>He leído la  <a href="{{url_for('core.privacy')}}" target="_blank" rel="noopener" class="text-primary pointer font-wieght-bold">política de privacidad y acepto el tratamiento de mis datos para la resolución de dudas o consultas que realice.</span>-->
                            </div>
                        </div>
                        <div class="text-center mt-2">
                            

                            <button v-if="sending==true" class="bttn btn-primar disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin  />{{ $t('Enviando')}}</button>
                            <h3 v-else-if="disabled==true" class="green"><font-awesome-icon icon="check" class="mr-2" />{{ $t('Formulario enviado correctamente')}}</h3>
                            <vue-recaptcha v-else sitekey="6LcGjFQiAAAAAApQFR1hTiOPPH2sTUhreDdNY7PG"
                                                    ref="invisibleRecaptcha"
                                                    @verify="onVerify"
                                                    @expired="onExpired"
                                                    size="invisible">
                                <!--<button class="bttn btn-primar" ><font-awesome-icon icon="envelope" class="mr-2" />
                                {{ $t('Enviar')}}</button>-->
                                <!-- <span class="container2"> -->
                                    <button  class="buttn small-buttn">
                                        <div class="button__line"></div>
                                        <div class="button__line"></div>
                                        <span class="button__text"><font-awesome-icon icon="envelope" class="mr-3" /> {{ $t('Enviar')}}</span>                                
                                    </button>
                                <!--</div>-->
                            </vue-recaptcha>
                                <!--<button v-else class="bttn btn-primar" @click="test_data()"><font-awesome-icon icon="envelope" class="mr-2" />
                                {{ $t('Enviar')}}</button>-->

                        </div>
                    </div>
                </div>
               
            </div>

            

        </div>
      <Foot/>
      <!--<div class="row justify-content-center" style="background-color: #000;">
          <div class="col-lg-3 col-md-5 col-sm-12 text-center m-4">
              <h4 style="color:#FFFFFF">Privacidad</h4>
          </div>
          <div class="col-lg-3 col-md-5 col-sm-12 text-center m-4">
              <h4 style="color:#FFFFFF">Cookies</h4>
          </div>
          <div class="col-lg-3 col-md-5 col-sm-12 text-center m-4">
              <h4 style="color:#FFFFFF">Privacidad</h4>
          </div>
          
          <div class="col-6 text-center m-1">
              <p class="white"> Design by GalaT<br>© KartekT MCD. Todos los derechos reservados 2022 </p>
          </div>
          
      </div>-->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { VueRecaptcha } from 'vue-recaptcha';

import ToTop from '@/components/ToTop.vue'
import NavBar from '@/components/NavBar.vue'
import Foot from '@/components/FooTer.vue'
import axios from "axios"


export default {
  name: 'web',
  components: {
      ToTop,
      NavBar,
      Foot,
      VueRecaptcha
  },

 // template: '<div><carousel :autoplay="true">' + buildSlideMarkup(10) + '</carousel></div>'
   data(){ 
     return{       
        recaptcha_loaded:false,
        locale:'',
        email:"",
        nombre:"",
        privacy:false,
        message:"",
        sending:false,
        disabled:false,
        expanded:false,
        loading:true,
        loading_cars:true,
        sold_cars:[
                {
                    brand:'',
                    engine:'',
                    fuel:'',
                    gearbox:'',
                    has_nft:false,
                    horsepower:'',
                    model:'',
                    traction:'',
                    year:'',
                    id:'',
                    imgs:{
                        min_imgs:{
                            front:[],
                            others:[]
                        }
                    }
                },   
            ],
        cars:[

            ] 
      }
  },   

  methods:{
        onSubmit: function () {
            this.$refs.invisibleRecaptcha.execute()
        },
        onVerify: function (response) {
            console.log('Verify: ' + response)
            this.validate_captcha(response)
        },
        onExpired: function () {
            console.log('Expired recaptcha')
        },
        resetRecaptcha() {
            this.$refs.recaptcha.reset() // Direct call reset method
        },

        validate_captcha: function (token) {
            this.sending=true
            let vm=this
            //console.log('va?', token, vm.nombre, vm.email, vm.message)
            if (vm.nombre!='' && vm.email!='' && vm.message!=''){
                //console.log("aqui")
                if (vm.privacy==true){
                    //console.log('Verify: ' + token)
                    axios.post('/api/landing/contact_form', { 
                                "message": vm.message,
                                "email":vm.email,
                                "name":vm.nombre,
                                "response_recaptcha":token
                        })
                        .then((result) => {
                            //console.log(result.data.resultado)
                            if (result.data.resultado === "Ok"){
                                vm.popToast("success", result.data.mensaje, "Ok")
                                vm.sending=false
                                vm.disabled=true
                            }
                            else if (result.data.resultado === "Error"){
                                vm.popToast("danger", result.data.mensaje, "Error")
                                vm.sending=false
                            }
                        }).catch((error) => {
                            console.log("error")
                            vm.sending=false
                            return(console.log(error));

                    });
                }
                else{
                    this.popToast("danger", "Por favor, acepte la política de privacidad para enviar el formulario.", "Error")
                    this.sending=false
                }
            }
            else{
                //console.log("alla")
                this.popToast("danger", "Por favor, rellene todos los campos para enviar el formulario.", "Error")
                this.sending=false
            }
        },
      getAllCarsStock(){
        this.loading_cars=true
        axios.get('/api/landing/get_cars')
            .then((result) => {
                //console.log("result",result)
                if (result.data.resultado === "Ok"){
                    this.cars=result.data.cars
                    //console.log("cars:",this.cars)
                    this.loading_cars=false
                }
                else if (result.data.resultado === "Error"){
                    this.popToast("danger", result.data.mensaje, "Error")
                    this.loading_cars=false
                }
            }).catch((error) => {
                console.log(error);
                this.loading_cars=false
            });
        },
        getAllCarsSold(){
            this.loading_cars=true
            axios.get('/api/landing/get_cars_sold')
                .then((result) => {
                    //console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.sold_cars=result.data.cars
                        //console.log("cars_sold:",this.sold_cars)
                        this.loading_cars=false
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                        this.loading_cars=false
                    }
                }).catch((error) => {
                    console.log(error);
                    this.loading_cars=false
                });
            },
      popToast(type, message) {
                    this.$bvToast.toast(message, {
                        //title: title,
                        variant: type,
                        solid: true,
                        toaster: 'b-toaster-top-center',
                    })
                },
      
      scrollToTop() {
          window.scrollTo(0, 0);
      },
      
  },
   mounted(){
    console.log("montando")
    this.email="",
    this.nombre="",
    this.privacy=false,
    this.message="",
    this.loading=false
    this.getAllCarsStock()
    this.getAllCarsSold()

  },

}
</script>
<style scoped>


/* Bottom right text */
.text-block {
position: absolute;
top: 5rem;
left: 5rem;
/*background-color: rgba(128, 128, 128, 0.4);*/
color: white;
/*padding-left: 200px;*/
padding-right: 200px;
text-align: justify;
}
.text-block-rs5{
    position: absolute;
    bottom: 5rem;
    left: 5rem;
    color: white;
    padding-right: 200px;
    text-align: justify;
}
.text-block-golf {
    position: absolute;
    bottom: 40rem;
    color: white;
    padding-left: 200px;
    padding-right: 200px;
    text-align: justify;
}
.text-block3 {
    position: absolute;
    top: 10rem;
    right: 10rem;
    color: white;
    padding-left: 200px;
    text-align: justify;
}
.text-block4 {
    position: absolute;
    bottom: 2rem;
    left: 0;
    color: white;
    padding-left: 200px;
    text-align: justify;
}
.text-block5 {
    position: absolute;
    bottom: 5rem;
    right: 5rem;
    color: white;
    padding-left: 200px;

    text-align: justify;
}
.sub-text{
    color: rgb(56, 56, 56);
}
.text-block-phone{
    
  position: absolute;
  top: 65%;
  /*background-color: rgba(225, 225, 227, 0.776);*/
  color:white;
  width:100%;
  text-align: center;
}
.text-block-phone2{
  position: absolute;
  top: 10%;
  left: 20px;
  color:white;
  width:100%;
  text-align: left;
}
.text-block-phone3{
  position: absolute;
  top: 15%;
  color:white;
  width:100%;
  text-align: center;
}
.text-block-phone4{
  position: absolute;
  bottom: 10%;
  left: 20px;
  color: white;
  width:100%;
  text-align: left;
}
.init-text{
  font-size: 6rem;
  /*color:white*/
}
.init-subtext{
  font-size: 3.5rem;
  /*color:white*/
}
.init-text-phone{
  font-size: 4rem;
}
.init-text-phone2{
  font-size: 4rem;
}
.inicio {
    max-height: calc(100vh);
    width: 100%;
    padding: 0!important;
}

.main-title{
  font-weight:bold;
  margin:20px;
  color: #0064BF;
  font-size:40px;
}
.second-title{
  font-weight:bold;
  margin:20px;
  color: black;
  font-size:40px;
}
.car-card{
    height: 100%;
}
.car-card:hover{
  transform: scale(1.05);
  transition: all .3s;

}
.black:hover{
  color: black;
}
/*.btn-app{
  position: relative;
  bottom:10px;
}*/
.text-decorator1{
    width:210px;
}
.text-decorator2{
    width:250px;
}
.phone-decorator{
    padding-top:30px;
    width:100px;
}
.v-360-navigate-btns{
  background-color:white!important;
}


.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: 2rem;
}

.overlay-extra {
    background-color: rgba(0, 0, 0, 0.5);
}
.extra-img {
    width: 100%;
   /* max-height: 200px;*/
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
}
.overlay-text {
    color: rgb(236, 12, 12);
    margin-top: 40%;
    font-size:4rem;
    transform: rotate(-30deg);
    font-weight: bold;
   /* font-family: 'Special Elite', cursive;*/
    transform: uppercase;
}


.coin{
    width: 8rem;
    height: 8rem;
    margin-right:2rem;
}
.nav-bitcoin{
    margin-left: 10px;
}

.big{
        font-size:2rem;
    }
.map{
    width: 500px;
    height: 350px;
}




@media only screen and (max-device-width : 767px){
    .navitem{
      color: black;
      margin: 0 10px;
    }
    .coin{
        width: 4rem;
        height: 4rem;
        margin:10px;
    }
    .mim{
        width:6rem; 
        height:6rem;
    }
    .init-text-phone{
      font-size: 2.5rem;
    }
    .init-text-phone2{
        font-size: 2rem;
    }
    .map{
        margin-top: 20px;
        width: 300px;
        height: 250px;
    }
    .phone-decorator{   
        padding-top: 0!important;    
        width:50px!important;
    }
  
    .contact-phone{
        padding: 0;        
    }
}

@media only screen and (min-width: 767px) and (max-device-width : 992px)  {

 }
 @media only screen and (min-width: 993px) and (max-device-width : 1300px) {
 
}

@media only screen and (min-width: 1301px) and (max-device-width : 1599px)  {
   
}

@media only screen and (min-width: 1601px) {
   

}

@media only screen
and (min-width : 1824px) {
    .navitem{
      color: black;
      margin: 0 50px;
    }
 
}

</style>
