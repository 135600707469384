import VueRouter from 'vue-router'
import Web from '../views/Web.vue'
import Car from '../views/Car-view.vue'
import MyCars from '../views/My-cars.vue'
import CarSold from '../views/Car-sold.vue'

import Legal from '../views/forms/LegalForm.vue'
import Cookies from '../views/forms/CookiesForm.vue'
import Privacy from '../views/forms/PrivacyForm.vue'

const routes = [
    {
        path: '/',
        component: Web,
        name: 'web',        
    }, 
    {
        path: '/cotxe-venda/:car_id',
        component: Car,
        name: 'car',
    },
    {
        path: '/meus-cotxes',
        component: MyCars,
        name: 'my_cars',        
    }, 
    {
        path: '/cotxe-venut/:car_id',
        component: CarSold,
        name: 'car_sold',        
    },
    {
        path: '/avis-legal',
        component: Legal,
        name: 'legal',        
    },
    {
        path: '/cookies',
        component: Cookies,
        name: 'cookies',        
    },
    {
        path: '/privadesa',
        component: Privacy,
        name: 'privacidad',        
    } 


   
]

const router = new VueRouter({
    mode: 'history',
    routes
  })
  
  export default router
  
