<template>
  <div id="car-sold" v-cloak>
    <div v-if="loading==true" class="row justify-content-center">
        <img class="spinner-logo" src="../../public/logo-spinner.svg" alt="nav-logo" spin />
    </div> 
    <div v-else >
    <EmptyNavBar/>
    <ToTop/>    
          <div class="d-none d-lg-block">         
            <swiper-container style="--swiper-navigation-color: #0064BF;" slides-per-view="1" navigation="true" zoom="true" keyboard="true" loop="true"  class="mySwiper"  thumbs-swiper=".mySwiper2">
                <swiper-slide v-for="image in carousel" :key="image.img_name">
                  <div  class="row justify-content-center">
                    <div class="swiper-zoom-container">
                      <v-lazy-image class="img-init-splide" alt="fotos del coche"
                        :src="image.url"
                        :src-placeholder="image.preview"
                      />
                    </div> 
                  </div> 
                </swiper-slide>
                      
            </swiper-container>
            <swiper-container class="thumbSwiper mySwiper2 mt-2" style="--swiper-navigation-color: #000;--swiper-navigation-size:25px"  navigation="true" loop="true"  space-between="5" :slides-per-view="8"  free-mode="true" watch-slides-progress="true">
              <swiper-slide v-for="image in carousel" :key="image.img_name">
                <div  class="row justify-content-center">
                  <v-lazy-image class="img-init-splide" alt="fotos del coche"
                    :src="image.url"
                    :src-placeholder="image.preview"
                  />
                </div>  
              </swiper-slide>
            </swiper-container>          
          </div>
          <div class="d-lg-none" >
            <swiper-container  style="--swiper-navigation-color: #0064BF;" slides-per-view="1" navigation="true" zoom="true" keyboard="true" loop="true"  class="mySwiper"  thumbs-swiper=".mySwiper3">
                <swiper-slide v-for="image in carousel" :key="image.img_name">
                  <div  class="row justify-content-center">
                    <div class="swiper-zoom-container">
                      <v-lazy-image  alt="fotos del coche"
                        :src="image.url"
                        :src-placeholder="image.preview"
                      />
                    </div> 
                  </div> 
                </swiper-slide>                      
            </swiper-container>
            <swiper-container class="thumbSwiper mySwiper3" style="--swiper-navigation-color: #000;--swiper-navigation-size:25px"  navigation="true" loop="true"  space-between="5" :slides-per-view="4"  free-mode="true" watch-slides-progress="true">
              <swiper-slide v-for="image in carousel" :key="image.img_name">
                <div  class="row justify-content-center">
                  <v-lazy-image alt="fotos del coche"
                    :src="image.url"
                    :src-placeholder="image.preview"
                  />
                </div>  
              </swiper-slide>
            </swiper-container>
          </div>
        <div class="row justify-content-center mb-4">
          <div class="col-lg-8 col-md-11 col-sm-12 text-center">
              <h1 class="title-car" id="stock">{{car_info.title[$i18n.locale]}}</h1>
              <p class="h4">{{car_info.kilometers}} km</p>
          </div>
        </div>
        <div class="row justify-content-center  text-center mb-4">
              <p class="block-icon-car">
                  <font-awesome-icon v-if="car_info.fuel.es=='Gasolina' || car_info.fuel.es=='Híbrido' || car_info.fuel.es=='Gasoil'" icon="gas-pump" class="icon-car-big" />
                  <font-awesome-icon v-if="car_info.fuel.es=='Eléctrico' || car_info.fuel.es=='Híbrido'" icon="bolt" class="icon-car-big"/><br>
                  {{car_info.fuel[$i18n.locale]}}
              </p>
              <p class="block-icon-car">
                  <img src="../../public/gearbox.svg" class="icon-car-big" alt="gearbox"><br> {{car_info.gearbox[$i18n.locale]}}
              </p>
              <p class="block-icon-car" >
                  <img src="../../public/horsepower.svg" class="icon-car-big" alt="gearbox" style="padding-bottom:10px"><br> {{car_info.horsepower}} 
                  <span v-if="[$i18n.locale]=='en'">hp</span><span v-else-if="[$i18n.locale]=='fr'">up</span><span v-else>cv</span>
              </p>
              <p  v-b-popover.hover.top="'Año'" class="block-icon-car">
                <font-awesome-icon icon="calendar" class="icon-car-big" /><br>
                {{car_info.year}}
              </p>    
          </div>   
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-11 col-sm-12 jus">
                <p class="h3" v-html=car_info.description[$i18n.locale]></p>
            </div>
          </div>

          <div class="row justify-content-center mt-4 mb-4" v-if="equip_with_img.length>0 || equip_without_img.length>0">
            <div class="col-lg-8 col-md-11 col-sm-12 jus">
              <h1 class="tecnic-title">{{ $t('Equipamiento')}}</h1>
            </div>           
          </div>
          <div class="row justify-content-center mt-4" v-if="equip_with_img.length>0">
              <div class="col-lg-3 col-md-8 col-sm-12 text-center mb-4" v-for="equip in equip_with_img" :key="equip.id_equip">
                <a class="card card-shadow black extra-card" @click="openDefaultModal(equip)">
                    <img class="extra-img" :src="equip.pictures[0].url" alt="detall de l'equipament de sèrie del cotxe" loading="lazy"> 
                    <div class="card-img-overlay overlay-extra extra-img">
                      <p class="overlay-text h2">{{equip.name[$i18n.locale]}}</p>           
                    </div>
                </a>
              </div>
          </div>
          <div class="row justify-content-center mt-4" v-if="equip_without_img.length>0">
            <div class="col-lg-8 col-md-12 col-sm-12" v-for="equip in equip_without_img" :key="equip.name.es">
                <ul class="ul-margin">
                  <li class="capi" v-if="equip.description[$i18n.locale]!=' '"><a class="h3" @click="openDefaultModal(equip)">{{equip.name[$i18n.locale]}}</a></li>                  
                  <li class="capi" v-else><p class="h3" >{{equip.name[$i18n.locale]}}</p></li>
                </ul>
            </div>
          </div>
          <b-modal id="defaultModal"  size="lg" hide-footer scrollable centered>
              <template #modal-header>
                  <a class="x" @click="$bvModal.hide('defaultModal')"><font-awesome-icon icon="times" /></a> 
                  <div class="col text-center p">
                      <p class="capi h3 bold m-0">{{current_equip.name[$i18n.locale]}}</p>
                  </div>                   
              </template>
              <div class="row justify-content-center mb-4" v-if="current_equip.pictures_mid[0].url!=undefined" >
                <div v-if="(current_equip.pictures_mid).length<2">
                  <vue-load-image>
                    <img  slot="image" class="extra-img" style="max-width:490px"  :src="current_equip.pictures_mid[0].url" alt="detall de l'equipament extra del cotxe" loading="lazy">
                    <img slot="preloader" class="card-img-spinner" style="max-width:490px" src="../../public/logo-spinner.svg"/>
                    <div slot="error">Error en carregar, recarregueu la pàgina</div>
                  </vue-load-image>
                </div>
                <b-carousel
                  v-else
                  :interval="6000"
                  controls
                  indicators
                  background="#fff"
                  >
                  <b-carousel-slide v-for="image in current_equip.pictures_mid" :key="image.img_name" style="max-width:75vh">
                      <template #img>
                        <vue-load-image>
                            <img slot="image" :src="image.url" style="max-width:490px" alt="foto extra" loading="lazy">
                            <img slot="preloader" class="card-img-spinner" style="max-width:490px" src="../../public/logo-spinner.svg"/>
                            <div slot="error">Error en carregar, recarregueu la pàgina</div>
                        </vue-load-image>
                     
                      </template>
                  </b-carousel-slide>           
                </b-carousel>    
              </div>       
              <div class="row justify-content-center">
                <p class="pl-2 pr-2 h4">{{current_equip.description[$i18n.locale]}}</p>
              </div>                      
          </b-modal>                      
         
          <div class="row justify-content-center mt-4 mb-4" v-if="extra_with_img.length>0 || extra_without_img.length>0">
              <div class="col-lg-8 col-md-11 col-sm-12 jus">
                <h1 class="tecnic-title">{{ $t('Extras')}}</h1>
              </div>              
            </div>
            <div class="row justify-content-center mt-4" v-if="extra_with_img.length>0">
              <div class="col-lg-8 col-md-12 col-sm-12 jus">
                <div class="row justify-content-center mt-4">
                    <div class="col-lg-5 col-md-8 col-sm-12 text-center mb-4" v-for="extra in extra_with_img" :key="extra.id_equip">
                        <a class="card card-shadow black extra-card" @click="openExtraModal(extra)">
                            <img class="extra-img" :src="extra.pictures[0].url" alt="detall de l'equipament extra del cotxe" loading="lazy"> 
                            <div class="card-img-overlay overlay-extra extra-img">
                              <p class="overlay-text h2">{{extra.name[$i18n.locale]}}</p>                       
                            </div>
                        </a>  
                    </div>
                </div>
              </div>
          </div>
          <div class="row justify-content-center mt-4" v-if="extra_without_img.length>0">
            <div class="col-lg-8 col-md-12 col-sm-12" v-for="extra in extra_without_img" :key="extra.name.es">
              <ul class="ul-margin">
                <li class="capi" v-if="extra.description[$i18n.locale]!=' '"><a class="h3" @click="openDefaultModal(extra)">{{extra.name[$i18n.locale]}}</a></li>                  
                <li class="capi" v-else><p class="h3" >{{extra.name[$i18n.locale]}}</p></li>
              </ul>
            </div>
          </div>

          <div class="row justify-content-center mt-4 mb-4" v-if="car_info.imgs_360.num_imgs>0">
            <div class="col-lg-8 col-md-11 col-sm-12">
              <h1 class="tecnic-title mt-4">{{ $t('Vista 360º del vehículo')}}</h1>
            </div>
              <div class="col-lg-8 col-md-6 col-sm-12 text-center">
                  
                  <div id="threesixty" style="display:none">
                    <vue-three-sixty ref="visor_360"             
                      :amount=car_info.imgs_360.num_imgs
                      :imagePath=car_info.imgs_360.path
                      :fileName=car_info.imgs_360.img_name
                      hotspots=[]
                      scrollImage
                    >
                    </vue-three-sixty>
                  </div>
                  <!--buttonClass="dark"-->
                  <button id="button-threesixty" class="bttn btn-primar" @click="showView360()"><font-awesome-icon icon="eye" class="mr-2" />{{ $t('Mostrar vista 360º del vehiculo')}}</button>
              </div>
            </div>            
            <div class="row justify-content-center" style="margin-top:100px">
              <div class="col-lg-8 col-md-11 col-sm-12 text-center mb-4">
                <h1 class="tecnic-title" id="contact"> {{ $t('¿Te gusta lo que ves?')}}</h1>
              </div>
            </div>
            <div class="row justify-content-center mt-4">
                <div class="col-lg-8 col-md-11 col-sm-12 text-center mb-4">
                    <p class="h3">{{ $t('contact_text_1')}}</p>
                </div>
              </div>
              <div class="row justify-content-center mt-4" style="margin-bottom:60px">
                <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                  <a title="Envíanos un mesaje por whatsapp" target="_blank" href="https://wa.me/376395375" ><font-awesome-icon :icon="['fab', 'whatsapp-square']" class="whatsapp" /></a>
                  <p class="h3">{{ $t('contact_text_2')}}</p>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                    <font-awesome-icon icon="envelope" class="mb-2 icon" />
                    <p class="h3">info@kartek.ad</p>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                    <font-awesome-icon icon="phone" class="mb-2 icon" />
                    <p class="h3">+376 395 375</p>
                </div>
                
            </div>
            <Foot/>
        </div>
  </div>
  
</template>
<script>
    /* eslint-disable */  
    import axios from "axios"

    import EmptyNavBar from '@/components/EmptyNavBar.vue'
    import Foot from '@/components/FooTer.vue'
    import ToTop from '@/components/ToTop.vue'

      export default {
        name: "car_sold",
        components: { 
          EmptyNavBar,
          Foot,
          ToTop
      }, 
      data() {
       return {
          loading:true,
          loading_cars:false,
          sending:false,         
          connected:false,
          deploying:false,
          claiming:false,
          creating:false,
          created:false,
          carousel:[],
          car_info:{"id":"", 
                    "brand":'',
                    "model":'', 
                    "year":'', 
                    "has_nft":false,
                    "price":'',
                    "price_string":'',
                    "title":'',
                    "description":'',
                    "imgs":{
                      "min_imgs":{
                        "front":[],
                          "others":[]
                      },
                      "mid_imgs":{
                          "front":[],
                          "others":[]
                      } 
                    },
                    "engine":'',
                    "horsepower":'',
                    "traction":{"es":'', "cat":'', "en":'', "fr":''},
                    "gearbox":{"es":'', "cat":'', "en":'', "fr":''},
                    "fuel":{"es":'', "cat":'', "en":'', "fr":''},
                    "def_equipment":[],
                    "extra_equip":"",
                    "nft_id":"",
                    "imgs_360":{"img_name":"" ,"num_imgs":0,"path":""},
                    "nft_info":{}
        },
        current_equip:{
          "description":{},
          "name":{},
          "id_equip":'',
          "pictures":[{"url":undefined}],
          "pictures_mid":[{"url":undefined}],
        },
      
        load_360:false,
        equip_without_img:[],
        equip_with_img:[],
        extra_without_img:[],
        extra_with_img:[],        
        }
      },
      computed: {
        
      },
      
      methods: {
        async getCarInfo(){
          this.loading_cars=true
          this.carousel=[]
          //this.thumb_carousel=[]
          axios.post('/api/landing/cars/get_info', {
            "car_id":this.car_id
          })
              .then(async (result) => {
                  //console.log("result",result)
                  if (result.data.resultado === "Ok"){
                      this.car_info=result.data.car_info                    
                     // console.log("car_info:",this.car_info)
                      if(this.car_info.imgs.mid_imgs.front.length>0){
                        this.carousel.push(this.car_info.imgs.mid_imgs.front[0])
                       // this.thumb_carousel.push(this.car_info.imgs.mid_imgs.front[0])
                        for (let i = 0; i < (this.car_info.imgs.mid_imgs.others).length; i++) {
                          this.carousel.push(this.car_info.imgs.mid_imgs.others[i])
                        //  this.thumb_carousel.push(this.car_info.imgs.min_imgs.others[i])
                        }
                      }else{
                        this.carousel=this.car_info.imgs.mid_imgs.others
                        //this.thumb_carousel=this.car_info.imgs.min_imgs.others
                      }
                      //console.log(this.carousel,'carousel')
                      this.formatEquip()
                      this.loading_cars=false
                      //this.mount_carrousels()
                      this.loading=false  
           
                  }
                  else if (result.data.resultado === "Error"){
                      this.popToast("danger", result.data.mensaje, "Error")
                      this.loading_cars=false
                  }
              }).catch((error) => {
                  console.log(error);
                  this.loading_cars=false
              });
        },
       
        sleep: function(milliseconds){
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        },  
  
        openDefaultModal(equip){
          this.current_equip={}
          this.current_equip=equip
          this.$bvModal.show('defaultModal')
        },
        
        openExtraModal(extra){
          //console.log('extra',extra)
          this.current_equip={}
          this.current_equip=extra
          this.$bvModal.show('defaultModal')
        },
        async showView360(){
          document.getElementById('threesixty').style.display = "block"
          document.getElementById('button-threesixty').style.display = "none"
          //this.load_360=true
          //await this.sleep(2000)
          this.$refs.visor_360.resetPosition()
        },
        formatEquip(){
          let i=0
          for(i = 0; i < (this.car_info.def_equipment).length; i++){
            //console.log(this.car_info.def_equipment[i].pictures)
            if((this.car_info.def_equipment[i].pictures[0].url)!=null){
              this.equip_with_img.push(this.car_info.def_equipment[i])
            }else{
              this.equip_without_img.push(this.car_info.def_equipment[i])
            }        
          }
          for(i = 0; i < (this.car_info.extra_equip).length; i++){
            //console.log(this.car_info.def_equipment[i].pictures)
            if((this.car_info.extra_equip[i].pictures[0].url)!=null){
              this.extra_with_img.push(this.car_info.extra_equip[i])
            }else{
              this.extra_without_img.push(this.car_info.extra_equip[i])
            }        
          }
        },
        goBackToWeb(){
          this.$router.push('/');
        },
      
        popToast(type, message) {
            this.$bvToast.toast(message, {
                variant: type,
                solid: true,
                noCloseButton: true,
                toaster: 'b-toaster-top-right',                
            })
        },    
       
      },
      mounted: async function(){
        this.car_id=this.$route.params.car_id
        //console.log("car_id",this.car_id)
        this.getCarInfo()     
      }
    }
</script>
<style scoped>
.spinner{
  font-size:20rem;
  margin-top: 200px;
}

.title-price{
  color: #0064BF;
  text-transform: uppercase;
  font-size: 6rem;
  text-align: center;
  font-weight:bold;
  margin: 1rem 0;
}

.tecnic-title{
font-size:2.6rem!important;
margin-bottom: 20px;
font-weight: bold;
margin-top: 40px;
}

.tecnic-list{
font-size:2rem!important;
padding-left: 10px;
}


.ul-tecnic{
list-style: none;
}

.ul-tecnic li:before {
content: '✓';
padding-right: 20px;
}

.extra-img{
  width: 100%;
  min-height: 75%;
  display: block; /* remove extra space below image */
  object-fit: cover;
}

.overlay-extra{
background-color: rgba(0, 0, 0, 0.5);
overflow-x: auto;
}
.overlay-extra:hover{
background-color: rgba(0, 0, 0, 0.1); 
transition: 0.4s;

}

.overlay-text{
color: white;
margin-top: 7%;
}

.capi:first-letter{
  text-transform: capitalize
}

/*swiper styles*/
.mySwiper{
  margin-top:54px;
}

.thumbSwiper { 
    width: 100%;
    height: auto;
  }

.thumbSwiper swiper-slide {
  opacity: 0.4;
  width: 25%;
  height: auto;

}
.thumbSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.thumbSwiper img {
  width: 80%;
  height: 100px;
}

.mySwiper3 img {
  width: 80%;
  height: 50px;
}

/* Sidebar sizes when expanded and expanded */
@media only screen
and (max-device-width : 767px){

  /*.img-car-phone{
    height:35vh;
  }*/
}


@media only screen and (min-width: 767px) and (max-device-width : 992px) and (orientation : portrait) {
    .ul-margin{
      margin-left: 35px;
    }
    .mySwiper3 img {
      width: 80%;
      height: 100px;
    }
}
@media only screen and (min-width: 767px) and (max-device-width : 992px) and (orientation : landscape)  {

    /*.img-car-phone{
    height:100vh;
    }*/
    .ul-margin{
      margin-left: 35px;
    }
}
@media only screen and (min-width: 993px) and (max-device-width : 1300px) {
}

</style>
