<template>
  <div id="app">
        <router-view></router-view>       
    </div>
</template>

<script>

//import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
//import ToTop from '@/components/ToTop.vue'
export default {
    name: "App",
    components: {
        
    },
    data() {
        return {          
        //showCollapse: false
        }
    },
    methods:{
       
      /*  popToast(type, message, title) {
            this.$bvToast.toast(message, {
                //title: title,
                variant: type,
                solid: true,
                toaster: 'b-toaster-top-center',
            })
        },*/
    }, 
    mounted(){
        /*document.getElementById("nav-collapse").addEventListener("click",this.open_nav )
        document.getElementById("app").addEventListener("click",this.close_nav )*/
    },
}
</script>


<style>

@import url('https://fonts.googleapis.com/css2?family=Metrophobic&display=swap');


:root{
    --main-color:#0064BF;
}
body{
    overflow-x: hidden ;
    font-family: 'Metrophobic', sans-serif!important;
    align-content: center;
   height:100%;
   color: #0c0c0c;

    }


/*    
h3{
    line-height: 1.5!important; 
}*/

[v-cloak] { display: none; }

.blue{
  color: #0064BF;
}
.red{
    color:rgb(204, 0, 0);
}
.grey{
    color:grey;
}
/*.green-text{
    color:#199b4b;
}*/
.black{
    color:black!important;
}
.white{
  color: #fff;
}

.bold{
    font-weight: bold;
}
.jus{
    text-align: justify;
}
.disabled{
  pointer-events: none!important;
  opacity:0.5;
}
.row{
    margin-left: 0px!important;
    margin-right: 0px!important;
}
.hand{
    cursor: pointer;
}
a{
    color:black;
}

a:hover{
  text-decoration: none!important;
}


a[aria-expanded=true] .fa-chevron-down {
    display: none;
}
a[aria-expanded=false] .fa-chevron-up {
    display: none;
}


.align{
    text-align: justify;
}
.font-big{
    font-size: 2.5rem!important;
}
.font-small{
    font-size: 1rem!important;
    padding-top: 5px;
}
.btn{
    border-radius: 1rem!important;
    padding: 0 0.75rem!important;
}

.bttn {
  display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 1.2rem;
   /* height:43px;*/
    line-height: 1.2;
    border-radius: 1rem;
    transition: none;
    font-weight:bold;
    font-size:1.5rem;
    text-transform: uppercase;
    min-height: 35px;
 }

.btn-primar{
   border: 1px solid #000!important;
    background-color: #000!important;/*#4776e5*/
    color: white!important;
}
.btn-primar:hover{
    border: 1px solid #000!important;
    background-color: white!important;
    color: #000!important;
    
    transform: translateY(0);
}
.btn-small{
        padding: 0 4px 1px 1px;
        border-radius:4px;
    }
.btn-second{
   border: 1px solid var(--main-color);
    background-color: var(--main-color);/*#4776e5*/
    color: white;
}
.btn-second:hover{
    border: 1px solid var(--main-color);
    background-color: white;
    color: var(--main-color)!important;
    
    transform: translateY(0);
}
.btn-grey{
    border: 1px solid grey;
    background-color: grey;/*#4776e5*/
    color: white;
}
.btn-grey:hover{
    border: 1px solid grey;
    background-color: white;
    color: grey!important;
    transform: translateY(0);
}

.btn-green{
    border: 1px solid rgb(4, 171, 4);
    background-color: rgb(4, 171, 4);
    color: white;
}
.btn-green:hover{
    border: 1px solid rgb(4, 171, 4);
    background-color: white;
    color: rgb(4, 171, 4)!important;
    
    transform: translateY(0);
}
.btn-btc{
   border: 1px solid #000;
    background-color: #F7931A;/*#4776e5*/
    color: #000;
    /*font-size:2.5rem!important;*/
}
.btn-btc:hover{
    border: 1px solid #F7931A;
    background-color: #000;
    color: #F7931A!important;
    
    transform: translateY(0);
}
.btn-dark{
    background-color: rgb(20, 20, 20)!important;
}
.addbtn{
        margin-top: 40px;
        margin-bottom: 15px;
}



/*special button */
:root{
    --line_color : #0c0c0c ;
}

/*button .buttn{
    position : relative ;
    z-index : 0 ;
   
    width:500px;
    height : 66px ;
    text-decoration : none ;
    font-size : 2rem ; 
    font-weight : bold ;
    color : var(--line_color) ;
    letter-spacing : 2px ;
    transition : all .3s ease ;
    text-transform: uppercase;

}*/
.checkbox{
    height: 20px;
    width: 20px;
    margin-right: 10px;
}
.buttn{
    position : relative ;
    z-index : 0 ;
   background-color: rgba(255, 255, 255, 0);
   border:0px;
    width:600px;
    max-width: 100%;
    min-height : 66px ;
    text-decoration : none ;
    font-size : 1.5rem ; 
    color : var(--line_color) ;
    letter-spacing : 2px ;
    transition : all .3s ease ;
    text-transform: uppercase;
}
.small-buttn{
    width:300px!important;
    height : 66px ;
}
.button__text{
    display : flex ;
    justify-content : center ;
    align-items : center ;
    width : 100% ;
    height : 100% ;
    padding: 5px;
}
.buttn::before,
.buttn::after,
.button__text::before,
.button__text::after{
    content : '' ;
    position : absolute ;
    height : 5px ;
    border-radius : 2px ;
    background : var(--line_color) ;
    transition : all .5s ease ;
}
.buttn::before{
    top : 0 ;
    left : 54px ;
    width : calc( 100% - 56px * 2 - 16px ) ;
}
.buttn::after{
    top : 0 ;
    right : 54px ;
    width : 8px ;
}
.button__text::before{
    bottom : 0 ;
    right : 54px ;
    width : calc( 100% - 56px * 2 - 16px ) ;
}
.button__text::after{
    bottom : 0 ;
    left : 54px ;
    width : 8px ;
}
.button__line{
    position : absolute ;
    top : 0 ;
    width : 56px ;
    height : 100% ;
    overflow : hidden ;
}
.button__line::before{
    content : '' ;
    position : absolute ;
    top : 0 ;
    width : 180% ;
    height : 100% ;
    box-sizing : border-box ;
    border-radius : 300px ;
    border : solid 5px var(--line_color) ;
}
.button__line:nth-child(1),
.button__line:nth-child(1)::before{
    left : 0 ;
}
.button__line:nth-child(2),
.button__line:nth-child(2)::before{
    right : 0 ;
}
.buttn:hover{
    letter-spacing : 6px ;
    color: #0064BF;
}
.buttn:hover::before,
.buttn:hover .button__text::before{
    width : 8px ;
}
.buttn:hover::after,
.buttn:hover .button__text::after{
    width : calc( 100% - 56px * 2 - 16px ) ;
}

.buttn:hover .green{
    color:#199b4b!important;
}

.buttn:hover .orange {
    color:#f6851b!important;
}

.container{
    width : 100% ;
    height : 200px ;
    display : flex ;
    flex-direction : column ;
    justify-content : center ;
    align-items : center ;
}
.container2{
    height : 100px ;
    width : 100% ;
    display : flex ;
    flex-direction : column ;
    justify-content : center ;
    align-items : center ;
}
/* end*/


.card-shadow{
    -webkit-box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.10);
    box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.10);
}

.card{
    border:1px solid rgba(73, 43, 43, 0.025)!important;
    border-radius:2rem!important;
}

.card-blue{
    background-color:rgba(209, 229, 255, 0.5)!important;
}

.form-label {
    font-size: 1.75rem;
    margin-left: 10px;
    /*text-transform: uppercase;*/
    /*color: #4696FE;*/
    /*font-weight: bold;*/
    /*padding-left: 5px;*/
    /*font-weight: bold!important;*/
}

.form-input{
    border: 3px solid #0064BF;/*rgb(122, 122, 122);*/
    background-color: white;
    width: 100%;
    padding: 0 1rem 3px 1rem;
    font-size: 1.8rem;
    border-radius: 2rem;
    min-height: 64px;
}

.form-input:hover{
    outline: transparent!important;
    border: 4px solid #0064BF;
}
.form-input:focus{
    outline: transparent!important; 
}

.vs__dropdown-toggle{
    border: 3px solid #0064BF!important;
    background-color: #d0e1fe6e!important;
    color: #FFFFFF!important;
    width: 100% !important;
    padding: 6px !important;
    font-size: 1.25rem !important;
    box-shadow: 0px 10px 10px -8px rgba(0,0,0,0.1) !important;
    border-radius: 2rem !important;
    height: 55px!important;
    z-index: 2;
}
.vs__dropdown-toggle:hover{
    border: 4px solid #0064BF!important;
    /*padding: 6px!important;*/
}
.vs__actions{
    padding: 5px!important;
}
.vs__open-indicator{
        fill: #0064BF!important;
}
.vs__search{
    margin-top: 0!important;
    padding: 0!important;
    color: rgb(67, 67, 67)!important;
    border: none!important;
    font-size: 1.25rem !important;
}
.vs__selected-options{
    overflow:hidden!important;
    white-space: nowrap;
}
.vs__selected{
    margin: 0!important;
    padding: 0!important;
    color: black!important;
    border: none!important;
}
.vs__clear{
    display: none;
}

/*toast*/

.popover{
    z-index:20!important;
}
.toast{
  border: #e2dfdd solid 1px!important;
  border-radius: 7px!important;
}
.b-toast{
  text-align: center!important;
    opacity: 0.9;
}
.toast-header {
    padding: 0 !important;
    margin: 0 !important;
    width: 0px !important;
    text-align: center;
}
.toast-body{
  padding: 10px!important;
  font-size:18px!important;
}


/* web*/
.section-web{
    min-height: 90vh;
}
.title-index{
    font-size: 60px;
    margin: 40px 0;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color:#4696FE;
}
.title-main{
    color: #000;
    text-transform: uppercase;
    font-size: 5rem;
    text-align: center;
    font-weight:bold;
    margin: 4rem 0;
}
.title-second{
    text-align: center;
    font-weight:bold;
    margin: 20px 0;
    font-size: 40px;
}
.title-car{
 
  text-transform: uppercase;
  font-size: 4rem;
  text-align: center;
  font-weight:bold;
  margin: 1rem 0;
}

.modal-dialog{
    border-radius: 7px;
}
  
.modal-label{
        font-size:1.25rem;
        text-align: left;
        margin:8px 0;
    }


.icon{
    height: 50px!important;
    width: 50px!important;
}
.whatsapp{
    width: 60px!important;
    height: 60px!important;
    color:#23C861;
}

.spinner-logo{
    width:500px;
    max-width: 100%;
    margin-top:300px;
}
.carousel{
    border-radius: 2rem;
}

.carousel-item{
    border-radius: 2rem;
}

.card-img{
    object-fit: cover;
    height:100%;
    width: 100%;
    
}

.carousel-init2{
    text-shadow: 1px 1px 2px rgb(8, 8, 8);
    object-fit: cover;
    height: 100%;
    width: 100%;
    justify-content: center;
}
.img-init2{
    width: 100%;
    height: 109vh;
    display: block; 
    object-fit: cover;
    margin-top:-150px;
}

.carousel-init{
    text-shadow: 1px 1px 2px rgb(8, 8, 8);
    object-fit: cover;
    /*height:100%;*/
    width: 100%;
}
.carousel-item {
    text-align: center;
    width: 100%;
}
    
.carousel-control-prev{
    font-size: 1.5rem;
    width: 5% !important;
    color: rgb(226, 223, 205);
    opacity:1!important;
}

.carousel-control-next{
    font-size: 1.5rem;
    color: rgb(226, 223, 205);
    width: 5% !important;
    opacity:1!important;
}

.card-img-top{
    height: 250px;
    object-fit: cover;
    border-radius:2rem 2rem 0 0!important;

}
.card-img-spinner{
    height: 250px;
    object-fit: cover;
}

.img-init{
    width: 100%;
    height: 95vh;
    display: block; /* remove extra space below image */
    object-fit: cover;
}

.img-init-splide{
    height: 85vh;
    display: block; /* remove extra space below image */
    object-fit: cover;
}
.img-init-phone{
    width: 100%;
    display: block; /* remove extra space below image */
    object-fit: cover;
}
.img-car-phone{
    width: 100%;
    display: block; /* remove extra space below image */
    object-fit: cover;
}


.show{
    display: block!important;
}

.spinner-cars{
    width:400px;
}

.block-icon-car{
    margin:0 25px;
    font-size: 1.75rem;

}
.icons-car{
    margin:0 10px;
}
.icon-car{
    width: 25px;
   font-size: 1.5rem;
}
.icon-car2{
    width: 25px;
    width: 28px;
    height: 28px;
}

.icon-car-big{
    width: 40px;
    font-size:40px;
}

.splide__arrow {
    /*background: #838383!important;*/
    opacity: 1!important;
}

.coin-meta{
    height: 2rem;
    widows: 2rem;
}
.coin-nav{
    height: 1.3rem;
   /* margin-right:2rem;*/
}

.back{
    padding-top: 2% ;
    padding-bottom:6%;
    background-image: url("../public/back2.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.back-cripto{
    padding-top: 3% ;
    padding-bottom:3%;
    background-image: url("../public/back-cripto.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*
.swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #bc4f4f;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-wrapper{
    left:-38%!important;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
*/
/*
.splide__list{
    transform:translateX(0px)!important;
}*/

/* Sidebar sizes when expanded and expanded */
@media only screen
and (max-device-width : 767px){
    .h3{
        font-size: 1.35rem!important;
    }
    .spinner-cars{
        width:250px;
    }
    .img-nosotros {
        height:270px;
        width: 100%;
    }
    .col-sm-8{
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-sm-9{
        flex: 0 0 75%;
        max-width: 75%;
    }.col-sm-10{
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-sm-11{
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .carousel-init{
        margin-top:50px;
    }
    .title-car{
        font-size: 2.5rem!important;           
    }
    .title-main{
        font-size: 2rem!important;
        margin: 2rem 0;
    }
    .align{
        text-align: left;
    }
 /*   .title-main-long{
        font-size: 2.5rem!important;
    }*/
    .card-img-top{
        height: 180px;
    }
    .img-car-phone{
        width: 100%;
        display: block; /* remove extra space below image */
        object-fit: cover;
        /*height:35vh;*/
    }
    #carousel-car-phone{
        margin-top:54px!important;
    } 
    .block-icon-car{
        margin:0 10px;
        font-size: 1.25rem;
    }

    .icon-car{
        font-size: 1.25rem;
    }
    .back-cripto{
        padding-top: 10%!important ;
        padding-bottom:30%!important;
        background-image: url("../public/back-cripto-phone.svg")!important;        
    }
    .back{
        padding-top: 20%!important ;
        padding-bottom:30%!important;
        background-image: url("../public/back-blue-phone.svg")!important;

    }
}

@media only screen and (min-width: 767px) and (max-device-width : 992px) and (orientation: portrait)  {
    .img-nosotros {
        height:500px;
        width: 100%;
    }  
    .img-car-phone{
        width: 100%;
        display: block; /* remove extra space below image */
        object-fit: cover;
    }
    .carousel-init{
        margin-top:50px;
    }
    .card-img-top{
        height: 180px;
    }
    #carousel-car-phone{
        margin-top:54px!important;
    } 
    .back-cripto{
        background-image: url("../public/back-cripto-phone.svg")!important;        
    }
    .back{
        background-image: url("../public/back-blue-phone.svg")!important;
    }
}
@media only screen and (min-width: 767px) and (max-device-width : 992px) and (orientation: lanscape)  {
    .img-nosotros {
        height:500px;
        width: 100%;
    }  
    .carousel-init{
        margin-top:50px;
    }
    .img-car-phone{
        height:100vh;
    }
    
    #carousel-car-phone{
        margin-top:58px;
    } 
    .card-img-top{
        height: 180px;
    }

}
@media only screen and (min-width: 993px) and (max-device-width : 1300px) and (orientation: portrait)  {
    .img-nosotros {
        height: 450px;
        width: 100%;
    }
    .carousel-init{
        margin-top:70px!important;
    }
    .card-img-top{
        height: 180px;
    }
}
@media only screen and (min-width: 993px) and (max-device-width : 1300px)and (orientation: lanscape)  {
    .img-nosotros {
        height: 450px;
        width: 100%;
    }
    .carousel-init{
        margin-top:62px!important;
    }
    .card-img-top{
        height: 180px;
    }
}

@media only screen and (min-width: 1301px) and (max-device-width : 1599px)  {
    .img-nosotros {
        height: 500px;
        width: 100%;
    }    
    .modal-xl{
        max-width: 1500px!important;
    }
    .card-img-top{
        height: 180px;
    }
    .carousel-init{
    margin-top:60px;
    }
}

@media only screen and (min-width: 1601px) {
    .img-nosotros {
        height:600px;
        width: 100%;
    }
    .card-img-top{
        height: 250px;
    }
    .carousel-init{
    margin-top:60px;
    }
}

@media only screen
and (min-width : 1824px) {
    .carousel-init{
    margin-top:60px;
    }
}


/* Belen */
/* https://unpkg.com/progressive-image@1.2.0/dist/index.css */
.progressive {
  position: relative;
  display: block;
  overflow: hidden;
}
.progressive img {
    height: 85vh;
    display: block; /* remove extra space below image */
    object-fit: cover;
}

.progressive img.preview {
  filter: blur(2vw);
  transform: scale(1.05);
}
.progressive img.hide {
  opacity: 0;
}
.progressive img.origin {
  position: absolute;
  left: 0;
  top: 0;
  animation: origin 1.5s ease-out;
}
.progressive img.origin-scale {
  position: absolute;
  left: 0;
  top: 0;
  animation: origin-scale 1s ease-out;
}
@-moz-keyframes origin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes origin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes origin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes origin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes origin-scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes origin-scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-o-keyframes origin-scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes origin-scale {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

</style>
