<template>
    <div class="">      
        <b-dropdown  :text="language" class="bttn p-0 ml-3" variant="dark">
            
            <b-dropdown-item  @click="switchLocale('cat')">CAT</b-dropdown-item>
            <b-dropdown-item  @click="switchLocale('es')">ES</b-dropdown-item>
            <b-dropdown-item  @click="switchLocale('fr')">FR</b-dropdown-item>
            <b-dropdown-item  @click="switchLocale('en')">EN</b-dropdown-item>
        </b-dropdown>
    </div>      
</template>

<script>


export default {
    name: "language_switcher",
    data() {
    return {
        locales:['cat','es','en','fr'],
        language:''
        }
    },

    methods: {
    switchLocale: function(locale){
        if (this.$i18n.locale !== locale){
            this.$i18n.locale = locale;
            this.language=this.$i18n.locale
        }

    },
    },
    mounted: function(){
        this.language=this.$i18n.locale
        console.log(this.language)
    }
}
</script>

<style scoped>
    .flag{
    position: absolute;
    top:60px;
    left:10px;
    }
    
    .language{
    font-size:24px;
    margin: 0 4px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    }
    .dropdown-toggle {
        background-color: black!important;
    }

</style>