<template>
    <nav class="navbar navbar-expand-lg navbar-light fixed-top nav-shadow " id="navbar" :class="{ 'navbar--hidden': !showNavbar }">
        <router-link  :to="{ name: 'web'}"  class="d-none d-md-block" style="margin-right:30px" title="presionar para volver a la pagina principal">
            <img class="nav-logo" src="../../public/logo.svg" alt="logo coche cotxe kartek">
        </router-link>
        <router-link  :to="{ name: 'web'}"  class="d-md-none" title="presionar para volver a la pagina principal">
            <img class="nav-logo" src="../../public/logo.svg" alt="logo coche cotxe kartek">
        </router-link>       
        <router-link :to="{ name: 'web'}" class="d-none d-md-block ml-auto nav-list" title="presionar para volver a la pagina principal"><div class="navitem" >{{ $t('Volver a la web')}}</div></router-link>
        <div class="row ml-auto">
           <!-- <a  title="Envíanos un mesaje por whatsapp" target="_blank" href="https://wa.me/376395375" ><div class="navitem"><font-awesome-icon :icon="['fab', 'whatsapp-square']" class="nav-whatsapp" /></div></a>
            <LanguageSwitcher />-->
            <a class="list-icon " title="Envíanos un mesaje por whatsapp" target="_blank" href="https://wa.me/376395375" ><img src="../../public/what.svg" class="coin-meta"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas"></a>
            <LanguageSwitcher />
        </div>
    </nav>
</template>
<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'


export default {
name: 'emptyNavBar',
components: {
  LanguageSwitcher,
},
data() {
    return {        
        showNavbar: true,
        lastScrollPosition: 0,
       // section:undefined
    }
},
methods:{
    onScroll () {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        if (currentScrollPosition < 0) {
            return
        }
        // Stop executing this function if the difference between
        // current scroll position and last scroll position is less than some offset
        if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 100) {
            return
        }
        this.showNavbar = currentScrollPosition < this.lastScrollPosition
        this.lastScrollPosition = currentScrollPosition
    },
    /*router(){
        this.section=this.$router.currentRoute.value
        console.log('id',this.section)
        this.section.replace("/#", "");
    }*/
}, 
mounted () {       
},
beforeDestroy () {
   // window.removeEventListener('scroll', this.onScroll)
}
}
</script>
<style>

#navbar{
background-color: white;
padding:0 1rem;
} 

.nav-logo{
padding-bottom:5px;

}

.nav-list{
padding: 10px;
}
.nav-list:hover{
background-color: #4696fe17;
border-radius: 7px;
}

.nav-list-icon{
padding: 10px;
}
.nav-list-icon:hover{
background-color: #4696fe17;
border-radius: 7px;
}
.navitem{
text-decoration:none;
font-size:25px;
font-weight: bold;
/*margin-top: 10px!important;*/
margin: 0 30px;
text-transform: uppercase;
}

.navitem:hover{
color: black;
font-weight: bold;
}
.navbar.toggler-icon{
color:#4696FE!important;
border:none;
}
.nav-shadow{
-webkit-box-shadow: 0 0 0.25rem 1rem rgba(0, 0, 0, 0.10);
-moz-box-shadow: 0 0 0.25rem 1rem rgba(0, 0, 0, 0.10);
box-shadow:  0 0.25rem 1rem rgba(0, 0, 0, 0.10);
}
.nav-whatsapp{
width: 40px!important;
height: 40px!important;
color:#23C861;
}
.nav-bitcoin{
width: 40px!important;
height: 40px!important;
color:#F7931A;
}

.navbar_toggler{
background-color: #000;
}
.navbar_toggler-icon{
background-color: #000;
}

.dropdown-menu{
min-width: fit-content!important;
}
.dropdown-toggle{
text-transform: uppercase;
}


.navbar.navbar--hidden {
box-shadow: none;
transform: translate3d(0, -100%, 0);
}

@media only screen
and (max-device-width : 767px){
.navitem{
  color: black;
  margin: 0 10px;
}
.nav-logo{
    width: 130px;
}
}
@media only screen and (min-width: 767px) and (max-device-width : 992px) and (orientation: landscape){
.navitem{
  color: black;
  margin: 0 10px;
}
.nav-logo{
    width: 100px!important;
}
}
@media only screen and (min-width: 767px) and (max-device-width : 992px)  {
.navitem{
  color: black;
  margin: 0 10px;
}
.nav-logo{
    width: 130px;
}
}
@media only screen and (min-width: 993px) and (max-device-width : 1300px) {
.navitem{
  color: black;
  margin: 0 20px;
}
.nav-logo{
    width: 150px;
}
}

@media only screen and (min-width: 1301px) and (max-device-width : 1599px)  {
.navitem{
  color: black;
  /*margin: 30px;*/
}
.nav-logo{
    width: 150px;
}
}

@media only screen and (min-width: 1601px) {

.navitem{
  color: black;
  margin: 0 50px;
}
.nav-logo{
    width: 150px;
}
}

@media only screen
and (min-width : 1824px) {
.navitem{
  color: black;
  margin: 0 50px;
}

.nav-logo{
    width: 150px;
}
}

</style>
