<template>
        <nav class="navbar navbar-expand-lg navbar-light fixed-top nav-shadow " id="navbar" :class="{ 'navbar--hidden': !showNavbar }">
       
            <router-link  :to="{ name: 'web'}"  class="d-none d-md-block" style="margin-right:30px" title="presionar para volver a la pagina principal">
                <img class="nav-logo" src="../../public/logo.svg" alt="logo coche cotxe kartek">
            </router-link>
            <router-link  :to="{ name: 'web'}"  class="d-md-none" title="presionar para volver a la pagina principal">
                <img class="nav-logo" src="../../public/logo.svg" alt="logo coche cotxe kartek">
            </router-link>          
        
            <a class=" list-icon d-none d-md-block d-lg-none" href="/#crypto" title="ir a la sección comprar con criptomonedas"><img src="../../public/coins/meta.svg" class="coin-meta"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas"></a>
            <a class="list-icon d-none d-md-block d-lg-none" title="Envíanos un mesaje por whatsapp" target="_blank" href="https://wa.me/376395375" ><img src="../../public/what.svg" class="coin-meta"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas"></a>

            <!--<a class="d-none d-md-block d-lg-none" title="Envíanos un mesaje por whatsapp" target="_blank" href="https://wa.me/376395375" ><font-awesome-icon :icon="['fab', 'whatsapp-square']" class="nav-whatsapp" /></a>-->
            <LanguageSwitcher class="d-none d-md-block d-lg-none"/>
            
            <b-navbar-toggle target="nav-collapse"> <span class="icon-nav when-open" style="padding:0 2px;"><font-awesome-icon icon="times" /></span><span class="icon-nav when-closed"><font-awesome-icon icon="bars"  /></span></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="text-center">
                    <a class="nav-list" href="/#stock"  title="ir a la sección stock de coches"><div class="navitem" >{{ $t('Stock')}}</div></a>
                    <a class="nav-list" href="/#sold"  title="ir a la sección coches vendidos"><div class="navitem" >{{ $t('A la carta')}}</div></a>
                    <a class="nav-list" href="/#we-are" title="ir a la sección nosotros"><div class="navitem"  >{{ $t('Nosotros')}}</div></a>
                    <!--<a class="nav-list" href="/#crypto" title="ir a la sección comprar con criptomonedas"> <div class="navitem"><font-awesome-icon :icon="['fab', 'bitcoin']" class="nav-bitcoin nav-item" /></div></a>-->
                     <!--<a class="nav-list pb-0 pt-2" href="/#crypto" title="ir a la sección comprar con criptomonedas"> <div class="navitem"><img src="../../public/coins/btc-meta.svg" class="coin-meta"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas"></div></a>-->
                     <a class="nav-list" href="/#crypto"  title="ir a la sección comprar con criptomonedas"><div class="navitem" >{{ $t('Crypt')}}<img src="../../public/coins/btc.svg" class="coin-nav mb-1"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas"></div></a>
                    
                    <a class="nav-list" href="/#contact"  title="ir a la sección contacto"><div class="navitem" >{{ $t('Contacto')}}</div></a>
                    <div class="row justify-content-center d-md-none mb-4">
                        <a class="list-icon" href="/#crypto" title="ir a la sección comprar con criptomonedas"><img src="../../public/coins/meta.svg" class="coin-meta"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas"></a>
                        <a class="list-icon" title="Envíanos un mesaje por whatsapp" target="_blank" href="https://wa.me/376395375" ><img src="../../public/what.svg" class="coin-meta"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas"></a>
                        <LanguageSwitcher class="mt-1"/>
                    </div>
                   
                </b-navbar-nav>
            </b-collapse>
            <a class=" list-icon  d-none d-lg-block" href="/#crypto" title="ir a la sección comprar con criptomonedas"><img src="../../public/coins/meta.svg" class="coin-meta"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas"></a>
            <a class="list-icon d-none d-lg-block" title="Envíanos un mesaje por whatsapp" target="_blank" href="https://wa.me/376395375" ><img src="../../public/what.svg" class="coin-meta"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas"></a>
            <LanguageSwitcher class="d-none d-lg-block"/>
        </nav>

        
</template>
<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'


export default {
  name: 'navBar',
  components: {
      LanguageSwitcher,
  },
  data() {
        return {        
            showNavbar: true,
            lastScrollPosition: 0,
            section:undefined,
        }
    },
    methods:{
        onScroll () {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            if (currentScrollPosition < 0) {
                return
            }
            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 100) {
                return
            }
            this.showNavbar = currentScrollPosition < this.lastScrollPosition
            this.lastScrollPosition = currentScrollPosition
        },
       /* router(){
            this.section=this.$router.currentRoute.value
            console.log('id',this.section)
            this.section.replace("/#", "");
        }*/
    }, 
    mounted () {
        window.addEventListener('scroll', this.onScroll)
      
       /* if (this.section!=undefined){
            this.$nextTick(()=> window.document.getElementById(section).scrollIntoView());
        }*/ 
           
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.onScroll)
    }
}
</script>
<style>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
#navbar{
  background-color: white;
  padding:0 1rem;
} 

.nav-logo{
    padding-bottom:5px;

}

.nav-list{
    padding: 10px;
}
.nav-list:hover{
    background-color: #4696fe17;
    /*border-radius: 7px;*/
}

.list-icon{
    padding: 4px;
    margin: 0 15px;
}

.navitem{
    text-decoration:none;
    font-size:25px;
    font-weight: bold;
    color: #0c0c0c;
    /*margin: 0 10px;    */
    /*margin-top: 10px!important;*/
   /* margin: 0 30px;*/
    text-transform: uppercase;
}

.navitem:hover{
    color: black;
    font-weight: bold;
}

.icon-nav{
    color: #000;
}
.navbar-toggler{
    border-color: #000!important;
}
.nav-shadow{
    -webkit-box-shadow: 0 0 0.25rem 1rem rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0 0 0.25rem 1rem rgba(0, 0, 0, 0.10);
    box-shadow:  0 0.25rem 1rem rgba(0, 0, 0, 0.10);
}
.nav-whatsapp{
    width: 40px!important;
    height: 40px!important;
    /*color:#23C861;*/
    border-radius: 1rem;
}

.nav-bitcoin{
    width: 40px!important;
    height: 40px!important;
    color:#F7931A;
}




.dropdown-menu{
    min-width: fit-content!important;
}
.dropdown-toggle{
    text-transform: uppercase;
}


.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

@media only screen
and (max-device-width : 767px){
    .navitem{
      margin: 0 10px;
    }
    .nav-logo{
        width: 130px;
    }
}
@media only screen and (min-width: 767px) and (max-device-width : 992px) and (orientation: landscape){
    .navitem{
      margin: 0 10px;
    }
    .nav-logo{
        width: 100px!important;
    }
 }
@media only screen and (min-width: 767px) and (max-device-width : 992px)  {
    .navitem{
      margin: 0 10px;
    }
    .nav-logo{
        width: 130px;
    }
 }
 @media only screen and (min-width: 993px) and (max-device-width : 1300px) {
    .navitem{
      margin: 0 20px;
    }
    .nav-logo{
        width: 150px;
    }
}

@media only screen and (min-width: 1301px) and (max-device-width : 1599px)  {
    .navitem{
      margin: 30px;
    }
    .nav-logo{
        width: 150px;
    }
}

@media only screen and (min-width: 1601px) {

    .navitem{
      margin: 0 50px;
    }
    .nav-logo{
        width: 150px;
    }
}

@media only screen
and (min-width : 1824px) {
    .navitem{
      margin: 0 50px;
    }
 
    .nav-logo{
        width: 150px;
    }
}

</style>
    