<template>
    <div id="privacy">
        <EmptyNavBar/>
        <div class="row justify-content-center">
            <h1  class="title-main">POLITICA DE COOKIES</h1>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-11 col-sm-12 align">
                <p class="h4">Última actualización: 25-09-2022</p>
                <p class="h4">Kartek MCD utiliza cookies en kartek.ad. Al usar kartek.ad, usted acepta el uso de cookies.</p>
                <p class="h4">La Política de cookies explica qué son las cookies, cómo las utilizamos, cómo terceros con los que podemos asociarnos pueden utilizar cookies en kartek.ad, sus opciones con respecto a las cookies y más información sobre las cookies.
                </p>
                <p class="title-second">¿Qué son las cookies?</p>
                <p class="h4">Las cookies son pequeños fragmentos de texto enviados por su navegador web por un sitio web que visita. Un archivo de cookies se almacena en su navegador web y permite que el Servicio o un tercero lo reconozca y haga que su próxima visita sea más fácil y el Servicio sea más útil para usted.</p>
                <p class="h4">Las cookies pueden ser cookies "persistentes" o de "sesión".</p>
                <p class="title-second">¿Cómo utiliza kartek.ad las cookies?</p>
                <p class="h4">Cuando utiliza y accede a kartek.ad, podemos colocar una serie de archivos de cookies en su navegador web.</p>
                <p class="h4">Utilizamos cookies para los siguientes propósitos: para habilitar ciertas funciones de kartek.ad, para proporcionar análisis y para almacenar sus preferencias.</p>
                <ul>
                    <li class="h4">Cookies esenciales. Podemos utilizar cookies esenciales para autenticar a los usuarios y evitar el uso fraudulento de cuentas de usuario.</li>
                </ul>
                <p class="title-second">Cookies de terceros</p>
                <p class="h4">Además de nuestras propias cookies, también podemos usar varias cookies de terceros para informar estadísticas de uso de kartek.ad, entregar anuncios de manera continua, y así sucesivamente.</p>
                <ul>
                    <li class="h4">Google Analytics (cookie propia): se utilizan para medir el tráfico global en nuestro sitio web y proporcionan datos totalmente anónimos y agregados sobre qué páginas se visitan, de dónde proviene el tráfico de nuestro sitio web y las tecnologías (como los sistemas operativos y el navegador versiones) utilizadas para acceder a nuestro sitio web. Hasta 2 años de duración. Si desea evitar que Google Analytics utilice sus datos, puede hacerlo descargando e instalando el complemento correspondiente para su navegador web desde el siguiente enlace: https://tools.google.com/dlpage/gaoptout? hl = lo es.</li>
                </ul>
                <p class="title-second">¿Cuáles son sus opciones con respecto a las cookies?</p>
                <p class="h4">Si desea eliminar las cookies o indicar a su navegador web que elimine o rechace las cookies, visite las páginas de ayuda de su navegador web.</p>
                <p class="h4">Sin embargo, tenga en cuenta que si elimina las cookies o se niega a aceptarlas, es posible que no pueda usar todas las funciones que ofrecemos, es posible que no pueda almacenar sus preferencias y que algunas de nuestras páginas no se muestren correctamente.</p>
                <p class="title-second">¿Dónde puede encontrar más información sobre las cookies?</p>
                <p class="h4">Puede obtener más información sobre las cookies y los siguientes sitios web de terceros:</p>
                <ul>
                    <li class="h4">AllAboutCookies: <a href="http://www.allaboutcookies.org/">Allaboutcookies</a></li>
                    <li class="h4">Network Advertising Initiative: <a href="http://www.networkadvertising.org/">Networkadvertising</a> </li>
                </ul>
            </div>
        </div>
        <Foot/>
    </div>

</template>
<script>
    import EmptyNavBar from '@/components/EmptyNavBar.vue'
    import Foot from '@/components/FooTer.vue'


    export default {
        name: "cookies_form",
        components: { 
          EmptyNavBar,
          Foot
        }
    }
</script>