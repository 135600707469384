<template>
    <div id="privacy">
        <EmptyNavBar/>
        <div class="row justify-content-center">
            <h1  class="title-main">POLITICA DE PRIVACIDAD</h1>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-11 col-sm-12 align">
                <p class="h4"> <span class="blue">Silatek, S.L.U.</span> informa a los usuarios del sitio web sobre su política respecto del tratamiento y protección de los datos de carácter personal de los usuarios y clientes que puedan ser recabados por la navegación o contratación de servicios a través de su sitio web. En este sentido,<span style="font-weight: bold">Silatek, S.L.U.</span> garantiza el cumplimiento de la normativa vigente en materia de protección de datos personales reflejada en el Reglamento General de Protección de Datos (RGPD) (UE) 2016/679 y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.</p>
                <p class="title-second">¿Quién es el responsable del tratamiento de datos personales?</p>
                <p class="h4"><span class="blue">Silatek, S.L.U.</span> con C.I.F. <span class="blue">L713544Y</span> con domicilio en <span class="blue">Carrer Pobladó 037, Naus Cortes Num.2, Santa Coloma AD500, Andorra la Vella </span> y correo electrónico <span class="blue">admin@ridid.me ó info@kartek.ad</span>.</p>
                <p class="title-second">¿Con que finalidad se tratan los datos personales?</p>
                <p class="h4"><span class="blue">Silatek, S.L.U.</span> trata la información que nos facilita con el fin de:</p>
                <ul>
                    <li class="h4">Prestarle el servicio solicitado, llevar a cabo la gestión de la aplicación y se su base de datos, así como la resolución de incidencias de esta. </li>
                    <li class="h4">Realizar la gestión interna societaria: administrativa, contable y fiscal.</li>
                </ul>
                <p class="title-second">¿Se enviará información comercial?</p>
                <p class="h4"><span class="blue">Silatek, S.L.U.</span> no realiza envío de información comercial, por lo tanto, no se realiza el tratamiento de datos con esta finalidad. </p>
                <p class="title-second">¿Cuánto tiempo se van a conservar los datos?</p>
                <p class="h4">Los datos personales proporcionados se conservarán mientras se mantenga la relación de prestación del servicio, hasta que solicite la supresión por parte del interesado o durante los años necesarios para cumplir con las obligaciones legales del responsable.</p>
                <p class="h4">Posteriormente, los datos serán suprimidos conforme a lo dispuesto en la normativa en materia de protección de datos, lo que implica su bloqueo, estando disponibles tan solo a solicitudes de Jueces y Tribunales, Defensor del Pueblo, Ministerio Fiscal o las Administraciones Públicas competentes durante el plazo de prescripción de acciones que pudieran derivar y, transcurrido este tiempo, se procederá a su completa eliminación.</p>
                <p class="title-second">¿Cuál es la legitimación para utilizar sus datos?</p>
                <p class="h4">La base legal para el tratamiento de datos es:</p>
                <ul>
                    <li class="h4">La necesidad para la ejecución del contrato de prestación de servicios suscrito con el cliente y cumplir con las obligaciones de él derivadas,</li>
                    <li class="h4">El consentimiento del usuario y la confirmación de la lectura de la política de privacidad para la resolución de dudas o consultas que se envíen a través del formulario de contacto presente en la página web. </li>
                </ul>
                <p class="title-second">¿A qué destinatarios se comunicarán los datos?</p>                
                <p class="h4">Además, los datos se cederán a terceros en los casos en los que exista una obligación legal como son:</p>
                <ul>
                    <li class="h4">Administración Tributaria</li>
                    <li class="h4">Bancos y Cajas de Ahorro</li>
                    <li class="h4">Fuerzas y Cuerpos de seguridad del Estado</li>
                </ul>
                <p class="h4">La información alojada en la aplicación se almacena en los servidores de Scaleway Elements ubicada en 8 rue de la Ville l’Evêque, 75008 París, Francia, la cual cumple con todas las directrices y obligaciones impuestas por el Reglamento (UE) 2016/679 del Parlamento Europeo y del consejo de abril de 2016.</p>
                <p class="title-second">¿Cuáles son sus derechos en materia de protección de datos?</p>
                <p class="h4">Cualquier persona tiene derecho a obtener confirmación sobre si en <span class="blue">Silatek, S.L.U.</span> se están tratando sus datos personales.</p>
                <p class="h4">Las personas interesadas tienen derecho a:</p>
                <ul>
                    <li class="h4">Solicitar el acceso a los datos personales relativos al interesado</li>
                    <li class="h4">Solicitar su rectificación o supresión</li>
                    <li class="h4">Solicitar la limitación de su tratamiento</li>
                    <li class="h4">Oponerse al tratamiento</li>
                    <li class="h4">Solicitar la portabilidad de los datos</li>
                </ul>
                <p class="h4">Los interesados podrán acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
                <p class="h4">En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaré para el ejercicio o la defensa de reclamaciones.</p>
                <p class="h4">En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. <span class="blue">Silatek, S.L.U.</span> dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. También podrán solicitar la portabilidad de sus datos.</p>
                <p class="h4">Los interesados también tendrán derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideran que el tratamiento de datos personales que le conciernen infringe el Reglamento.</p>
                <p class="title-second">¿Cómo se pueden ejercer los derechos?</p>                
                <p class="h4">Para el ejercicio de los derechos mencionados de acceso, rectificación, supresión limitación, oposición y portabilidad, los clientes o usuarios deberán dirigirse a <span class="blue">Silatek, S.L.U.</span> a través del correo electrónico<span class="blue">admin@ridid.me</span> acreditando siempre su identidad. </p>
                <p class="title-second">¿Cómo se han obtenido los datos personales?</p>                
                <p class="h4">Los datos personales que se tratan en <span class="blue">Silatek, S.L.U.</span> vienen procedentes del propio interesado  y de los hoteles u organizaciones que tengan contratado nuestro servicio. .</p>
                <p class="h4">La categoría de datos que se tratan son:</p>
                <ul>
                    <li class="h4">Datos de carácter identificativo: Nombre y apellidos, DNI, N.º de pasaporte, NIE, dirección postal, correo electrónico, firma.</li>
                    <li class="h4">Datos de características personales: Sexo, fecha de nacimiento, nacionalidad, lugar de nacimiento.</li>
                </ul>
                <p class="title-second">¿Ante qué órgano se puede reclamar?</p>
                <p class="h4">Podrá presentar reclamación ante la Autoridad de Control en materia de Protección de Datos competente, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, mediante escrito dirigido a la Agencia Española de Protección de Datos, C/Jorge Juan nº 6, 28001 Madrid o bien accediendo a su sede electrónica: <a href="https://sedeagpd.gob.es/sede-electronica-web/">https://sedeagpd.gob.es/sede-electronica-web/</a>.</p>
            </div>
        </div>
        <Foot/>
    </div>

</template>

<script>
    import EmptyNavBar from '@/components/EmptyNavBar.vue'
    import Foot from '@/components/FooTer.vue'


    export default {
        name: "privacy_form",
        components: { 
          EmptyNavBar,
          Foot
        }
    }
</script>