<template>
    <div id="my-cars" v-cloak>
        <div v-if="loading==true" class="row justify-content-center">
            <img class="spinner-logo" src="../../public/logo-spinner.svg" alt="nav-logo" spin />
        </div> 
        <div v-else>            
            <EmptyNavBar/>
            <!--<ToTop/>    -->
            <div id="stock"></div>
            <div class="back section-web">
                <div class="row justify-content-center">                
                    <h1  class="title-main">{{ $t('Tus NFTs de coches')}}</h1>
                </div>           
                <div v-if="metamask_connected==false && connecting==false" class="row justify-content-center" style="margin:50px 0 60px 0">
                    <div class="col-12 text-center">
                        <div class="container2">
                            <button class="buttn"  @click="connect_metamask()">
                                <div class="button__line"></div>
                                <div class="button__line"></div>
                                <span class="button__text orange"><img src="../../public/coins/meta.svg" class="coin-meta mr-2 d-none d-md-block"  target="_blank" rel="noopener">{{ $t('Conectar Metamask')}}</span>                                
                            </button>
                        </div>
                        <!-- <a class="bttn btn-btc font-big" @click="connect_metamask()">{{ $t('Conectar Metamask')}}</a> -->
                        <p class=" h5 mt-2">{{ $t('¿No tienes metamask?')}} <a href="https://metamask.io/download/" target="_blank" rel="noopener" alt="enllaç per descarregar metamask" class="blue">{{ $t('Descárgalo aquí')}}</a>. {{ $t('Sencillo y seguro')}}.  </p>
                        <a class="h5 grey" href="https://academy.binance.com/es/articles/how-to-add-fantom-to-metamask" target="_blank" rel="noopener" title="enllaç al tutorial de metamask" >{{ $t('Aprende como usarlo con este tutorial')}}</a>
                    </div>                        
                    <!--  <div class="col-12 text-center">
                            <a class="bttn btn-btc font-big" @click="connect_metamask()">{{ $t('Conectar Metamask')}}</a> 
                            <p class=" h4 mt-4">{{ $t('¿No tienes metamask?')}} <a href="https://metamask.io/download/" target="_blank" rel="noopener" title="enllaç per descarregar metamask" class="blue">{{ $t('Descárgalo aquí')}}</a>. {{ $t('Sencillo y seguro')}}.  </p>
                            <a class="h5 blue" href="https://academy.binance.com/es/articles/how-to-add-fantom-to-metamask" target="_blank" rel="noopener" title="enllaç al tutorial de metamask">{{ $t('Aprende como usarlo con este tutorial')}}</a>
                        </div>-->
                </div>        
                <div v-else-if="metamask_connected==true && loading_cars==false" class="row justify-content-center">
                    <div v-if="cars.length==0">
                        <p class="h4">{{ $t('La billetera conectada no tiene NFTs asociados')}}.</p>
                    </div>
                    <div v-else class="row justify-content-center">
                        <div class="col-lg-10 col-ms-12 col-sm-12 align">
                            <p class="h4">{{ $t('Presiona')}} <span class="blue">'{{ $t('Canjear NFT coche')}}'</span> {{ $t('text_1')}}</p>    
                        </div>      
                        <div  class="col-lg-3 col-md-5 col-sm-12 align m-1 mt-4" v-for="car in cars" :key="car.name">
                            <div class="card card-shadow">                        
                                <div v-if="(car.imgs.min_imgs.front).length==0" class="card-img">
                                    <img src="../../public/logo.svg" class="card-img-top p-2" alt="coche">
                                </div>                
                                <div v-else class="card-img" >
                                    <img :src="car.imgs.min_imgs.front[0].url" class="card-img-top"/>                       
                                </div>                     
                                <div class="card-body text-center">                          
                                    <h2 class="bold">{{car.brand}} {{car.model}} {{car.engine}}</h2>
                                    <p class="h4">{{car.kilometers}} km</p>
                                    <div class="row justify-content-center">
                                        <p class="icons-car">
                                            <font-awesome-icon v-if="car.fuel.es=='Gasolina' || car.fuel.es=='Híbrido' || car.fuel.es=='Gasoil'" icon="gas-pump" class="icon-car" />
                                            <font-awesome-icon v-if="car.fuel.es=='Eléctrico' || car.fuel.es=='Híbrido'" icon="bolt" class="icon-car"/><br>
                                            {{car.fuel[$i18n.locale]}}
                                        </p>
                                        <p class="icons-car">
                                            <img src="../../public/gearbox.svg" class="icon-car2" alt="gearbox"><br> {{car.gearbox[$i18n.locale]}}
                                        </p>
                                        <p class="icons-car">
                                            <img src="../../public/horsepower.svg" class="icon-car2" alt="gearbox"><br> {{car.horsepower}} 
                                            <span v-if="[$i18n.locale]=='en'">hp</span><span v-else-if="[$i18n.locale]=='fr'">up</span><span v-else>cv</span>
                                        </p>
                                        <p  v-b-popover.hover.top="'Año'" class="mr-4">
                                            <font-awesome-icon icon="calendar" class="icon-car" /><br>
                                            {{car.year}}
                                        </p>  
                                    </div>
                                    <!--<p>ID {{car.nft_id}} </p>--> 
                                    <div v-if="car.Claimed==true">
                                        <p class="h3 green mt-2 mb-4"> <font-awesome-icon icon="check" class="mr-2 green" />{{ $t('NFT canjeado')}}</p>

                                        <div v-if="car.DetailingSessions>0">
                                            <div class="container2">
                                                <button class="buttn" @click="signDetailingClaim(car.nft_id)">
                                                    <div class="button__line"></div>
                                                    <div class="button__line"></div>
                                                    <span class="button__text">{{ $t('Canjear detailing')}}</span>                                
                                                </button>
                                            </div>   
                                            <!--<button class="bttn btn-primar" @click="signDetailingClaim(car.nft_id)" >{{ $t('Canjear detailing')}}</button>-->
                                            <div v-if="signature_claim_detailing[car.nft_id]!=undefined" class="mt-4">
                                            <!-- <p>{{ $t('QR del detailing')}}: {{signature_claim_detailing[car.nft_id]}}</p>
                                                <p>{{ $t('Envíanos este comprobante')}}.</p>    -->
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p class="red">{{ $t('No quedan sesiones de detailing asociados a este vehículo')}}, 
                                            <a href="https://wa.me/376395375" arget="_blank" rel=" noopener" alt="comprar detailing por whatsapp">{{ $t('¿Quieres más?')}}</a>.</p>
                                        </div>
                                        <div class="container2">
                                            <button class="buttn" @click="openSendNft(car)">
                                                <div class="button__line"></div>
                                                <div class="button__line"></div>
                                                <span class="button__text">{{ $t('Transferir')}}</span>                                
                                            </button>
                                        </div>   
                                    
                                        <!--<button  class="bttn btn-grey mt-2" @click="openSendNft(car)">{{ $t('Transferir')}}</button>    -->
                                    </div>                    
                                    <div v-if="car.Claimed==false">         
                                        <div class="container2">
                                            <button class="buttn" @click="signCarClaim(car.nft_id)">
                                                <div class="button__line"></div>
                                                <div class="button__line"></div>
                                                <span class="button__text">{{ $t('Canjear NFT coche')}}</span>                                
                                            </button>
                                        </div>                                                       
                                        <!--<button class="bttn btn-primar" @click="signCarClaim(car.nft_id)">{{ $t('Canjear NFT coche')}}</button>                                -->
                                        <div v-if="signature_claim_car[car.nft_id]!=undefined" class="mt-4">
                                            <!--<p>{{ $t('QR del vehículo')}}: {{signature_claim_car[car.nft_id]}}</p> 
                                            <p>{{ $t('Envíanos este comprobante')}}.</p>    -->
                                        </div>
                                        <div class="container2">
                                            <button class="buttn" @click="openSendNft(car)">
                                                <div class="button__line"></div>
                                                <div class="button__line"></div>
                                                <span class="button__text">{{ $t('Transferir')}}</span>                                
                                            </button>
                                        </div>   
                                        <!--<button  class="bttn btn-grey mt-2" @click="openSendNft(car)">{{ $t('Transferir')}}</button>                                -->
                                    </div>                                                            
                                </div>            
                            </div>                         
                        </div>
                    </div>
                <!-- <div class="col-lg-10 col-ms-12 col-sm-12 text-center mt-4"> 
                        <p class="h4">Para saber más sobre Las sesiones de <a class="" href="https://detailpark.com/como-hacer-un-buen-detailing-los-12-pasos-del-principiante"  target="_blank" rel="noopener" title="información sobre detailing" alt="información sobre detailing">Detailing</a>, para concertar hora o comprar más has de ponerte en contacto con nosotros <a href="https://wa.me/376395375" arget="_blank" rel=" noopener" alt="comprar detailing por whatsapp">aquí</a>.</p>
                    </div>-->
                </div>      
                <div v-else class="row justify-content-center" >
                    <img class="spinner-cars" src="../../public/logo-spinner.svg" alt="loading sold cars"  />
                    <div class="col-12 text-center">
                        <p class="h3">{{ $t('Cargando')}}...</p>
                    </div>
                </div>  
            </div>   
        </div> 
        <b-modal id="sendNftModal"  size="lg" hide-footer scrollable centered>
            <template #modal-header>
                <a class="x" @click="$bvModal.hide('sendNftModal')"><font-awesome-icon icon="times" /></a> 
                <div class="col text-center">
                    <p class="h1 bold m-0">{{ $t('Transferir ')}}NFT</p>
                </div>                   
            </template>
            <div class="row justify-content-center">
                <p class="h4">{{ $t('Introduce la dirección de la billetera a la que quieres transferir el NFT')}}.</p>
                <div class="col-12 text-left mt-4">
                    <label class="form-label blue" for="direccion">{{ $t('Dirección billetera')}}</label>
                    <input class="form-input" type="text" v-model="new_wallet_address" id="direccion">
                </div>
            </div>   
            <div class="text-center mt-4">
                <div class="container2">
                    <button v-if="sending_nft==true" class="buttn">
                        <div class="button__line"></div>
                        <div class="button__line"></div>
                        <span class="button__text">{{ $t('Enviando')}}<font-awesome-icon icon="circle-notch" spin  class="ml-3 d-none d-md-block" /></span>                                
                    </button>
                    <button v-else class="buttn" @click="sendNft()">
                        <div class="button__line"></div>
                        <div class="button__line"></div>
                        <span class="button__text">{{ $t('Enviar')}}</span>                                
                    </button>
                </div>   
            
               <!-- <button v-if="sending_nft==true" class="bttn btn-primar"><font-awesome-icon icon="circle-notch" class="mr-2" spin  />{{ $t('Enviando')}}</button>
                <button v-else class="bttn btn-primar" @click="sendNft()">{{ $t('Enviar')}}</button>
                <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('sendNftModal')">{{ $t('Cerrar')}}</a>-->
            </div>
        </b-modal>

    </div> 
</template>
<script>
  /* eslint-disable */
//import ToTop from '@/components/ToTop.vue'
import EmptyNavBar from '@/components/EmptyNavBar.vue'

const car_collection_abi=require('../contracts_abis/abi_car_collection.json');
const erc_20_abi=require('../contracts_abis/abi_erc20.json');
import _ from 'underscore'
const contracts_addresses =require('../contracts_addresses.json')
import { ethers, utils, BigNumber} from 'ethers'
import { serializeError } from "eth-rpc-errors";
import axios from "axios"
const AddressCarCollection=process.env.VUE_APP_ADDRESS_CAR_COLLECTION
  export default {
      name: "my-cars",
      components: { 
        EmptyNavBar,
       // ToTop
    }, 
    data() {
      return {
        metamask_connected:false,
        has_listeners:false,
        loading:false,
        loading_cars:false,
        sending:false,
        cars:[],
        connecting:false,
        signature_claim_detailing:{},
        signature_claim_car:{},
        nft_id:0,
        new_wallet_address:'',
        sending_nft:false,
        car_info:{"id":"2", 
                  "brand":'audi',
                  "model":'a3', 
                  "year":'2022', 
                  "price":100000,
                  "price_string":'100000',
                  "":'Audi a3 super chulo',
                  "description":'miaumiaumiaumiau',
                  "imgs":{
                      "mid_imgs":{
                        "front":[],
                        "others":[]
                    },
                    "full_imgs":{
                        "front":[],
                        "others":[]
                    } 
                  },
                  "engine":'ASa',
                  "horsepower":'450cv',
                  "traction":{"es":'4', "cat":'4', "en":'4', "fr":'4'},
                  "gearbox":{"es":'manual', "cat":'manual', "en":'manual', "fr":'manual'},
                  "fuel":{"es":'petrol', "cat":'petrol', "en":'petrol', "fr":'petrol'},
                  "def_equipment":[],
                  "extra_equip":"",
                  "nft_id":"",
                  "imgs_360":{"img_name":"" ,"num_imgs":0,"path":""},
                  "nft_info":{}
      },
      }
    },
    methods: {
        

        /*
        CONTRACT STUFF
        */
       async signDetailingClaim(_carID){
            let hash_to_sign=await this.car_collection_contract_read_only.getHashToSign(_carID, "detailing")
            //console.log("hash_to_sign", hash_to_sign)
            this.signature_claim_detailing[_carID]= await window.ethereum.request({ method: 'personal_sign', params: [this.my_eth_address, hash_to_sign] })
            //console.log("signature claim detailing for carID", _carID, this.signature_claim_detailing)
            this.downloadVoucherDetailing(this.signature_claim_detailing[_carID], _carID)
            this.$forceUpdate()
       },

       async signCarClaim(_carID){
            let hash_to_sign=await this.car_collection_contract_read_only.getHashToSign(_carID, "car")
            //console.log("hash_to_sign", hash_to_sign)
            this.signature_claim_car[_carID]= await window.ethereum.request({ method: 'personal_sign', params: [this.my_eth_address, hash_to_sign] })
            //console.log("signature claim car with carID", _carID, this.signature_claim_car)
            this.downloadVoucher(this.signature_claim_car[_carID], _carID)
            this.$forceUpdate()
       },
     
        async getCarsNftInfo(){
            for (var i=0; i<this.cars.length; i++){
                let nft_info=await this.car_collection_contract_read_only.Cars(this.cars[i]["nft_id"])
                this.cars[i]["Claimed"]=nft_info.Claimed
                this.cars[i]["DetailingSessions"]=nft_info.DetailingSessions.toNumber()
                
                //console.log("nft_info", nft_info)
                //cars_ids.push(car_id.toNumber())
            }
            this.$forceUpdate()

        },

        
       async getMyCars(){
            this.$forceUpdate()
            this.loading_cars=true
            let num_cars=await this.car_collection_contract_read_only.balanceOf(this.my_eth_address)
            //console.log("tienes", num_cars.toNumber(), "coches")
            let indexes_of_cars_owned=[]
            let cars_ids=[]
            
            for (var i=0; i<num_cars; i++){
                indexes_of_cars_owned.push(i)
            }
            const cars_owned= await Promise.all(indexes_of_cars_owned.map(x=>this.car_collection_contract_read_only.tokenOfOwnerByIndex(this.my_eth_address,x)))
            for (var x=0;x<cars_owned.length;x++){
                cars_ids.push((cars_owned[x]).toNumber())
            }
            //console.log("cars_ids",cars_ids)
            //const tokens_owned= await Promise.all(indexes_of_tokens_owned.map(x=>this.TicketsContract.tokenOfOwnerByIndex(this.my_eth_address,x)))

            axios.post('/api/landing/cars/get_by_nft_ids', {
                "cars_nfts_ids":cars_ids
            })
                .then((result) => {
                    //console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.cars=result.data.cars
                        //console.log("cars:",this.cars)
                        this.loading_cars=false
                        this.getCarsNftInfo()
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                        this.loading_cars=false
                    }
                }).catch((error) => {
                    console.log(error);
                    this.loading_cars=false
                });
       },
        /*
        Metamask stuff
        */
       handle_account_change(){
            this.connect_metamask()
        },

        async handle_chain_change(chainId){
            if(chainId==0xfa){ //mainnet or fork  || chainId==0x7a69
              this.connect_metamask()
          }
          
          else{
            this.popToast("danger", `Please, change to Fantom network`)
            this.metamask_connected=false
            this.web3_read_provider=undefined
            this.web3_provider=undefined
            this.my_eth_address=undefined
          }
        },

        async setup(){
          this.load_read_only_ws_provider()
          //console.log("this.web3_read_provider",this.web3_read_provider)
          //console.log("this.signer",this.signer)
          if (this.metamask_connected==true){
            this.car_collection_contract = new ethers.Contract(AddressCarCollection, car_collection_abi["abi"], this.signer)
            this.getMyCars()

          }        
        },

        async handle_tx_confirmation(tx_object){
          let result=undefined
          
          try{
              //console.log("handling tx")
              result = await this.web3_read_provider.waitForTransaction(tx_object.hash)
              //console.log("bien")
              return(true, result)
          }
          catch(error){
              this.popToast("error", error)
              //console.log("mal")
              return(false, error)
          }
      },
        handle_metamask_rpc_error(serialized_error){
            //console.log("serializedError",serialized_error );
            if (serialized_error.data!=undefined){
                this.popToast("danger", serialized_error.data.originalError.reason)
            }
            else{
                this.popToast("danger", serialized_error.message)
            }
        },
        async connect_metamask(){ 
            this.$gtag.event('connect_metamask', { method: 'ConnectMetamask' })
            this.connecting=true
            let vm=this
            try{
                if(window.ethereum) {
                //let accounts= await window.ethereum.enable();
                    if (this.has_listeners==false){
                        //console.log("creating listener", window.ethereum.listenerCount('accountsChanged'))
                        window.ethereum.on('accountsChanged', function () {
                            vm.handle_account_change();
                        })
                        
                        window.ethereum.on('chainChanged', function (chainId) {
                            vm.handle_chain_change(chainId)
                        })
                        this.has_listeners=true
                    }
                    
                    
                    
                    let chainId= await window.ethereum.request({ method: 'eth_chainId' })
                    console.log("chainId", chainId)
                    if (chainId==0xfa){ //0xfa2 para testnet  || chainId==0x7a69
                        await window.ethereum.request({ method: 'eth_requestAccounts' });
                        this.web3_provider=await new ethers.providers.Web3Provider(window.ethereum)
                        //console.log("this.web3_provider",this.web3_provider)
                        this.signer=this.web3_provider.getSigner()
                        //console.log("signer", this.signer)
                        this.my_eth_address= await window.ethereum.selectedAddress
                        //console.log('address',this.my_eth_address)
                        this.metamask_connected=true
                        this.connecting=false
                        this.popToast("success", `Successfuly conected wallet`)
                        await this.setup()
                    }
                    else{
                        this.popToast("danger", `Please, change to Fantom network`)
                        this.connecting=false
                        this.metamask_connected=false
                        
                    }
                    
                }
                else{
                    this.metamask_connected=false
                    this.connecting=false
                    this.popToast("danger", `Please, install metamask`)
                }        
            }

            catch(error){
                console.log(error)
                this.popToast("danger", `Error connecting metamask: ${error.message}`)
                this.metamask_connected=false
                this.connecting=false


            }
            
            //return(this.web3_provider)   
        },

        load_read_only_ws_provider: function(){
            this.web3_read_provider= new ethers.providers.JsonRpcProvider("https://rpcftm.kartek.ad")
            //console.log("web3_read_provider", this.web3_read_provider)
            this.car_collection_contract_read_only = new ethers.Contract(AddressCarCollection, car_collection_abi["abi"], this.web3_read_provider); 
        },
        
        openSendNft(current_car){
            this.current_car=current_car
            //console.log("this.current_car", this.current_car)
            this.$bvModal.show('sendNftModal')
        },
        /*openSendNft(){
            this.$bvModal.show('sendNftModal')
        },*/
        async sendNft(){ //0xA879ACc3108f329072A23800b2c14636824D3C7f
            //console.log("aquiii", this.my_eth_address, this.new_wallet_address, this.current_car.nft_id)
            this.sending_nft=true
            try{

                let tx = await this.car_collection_contract.transferFrom(this.my_eth_address, this.new_wallet_address, this.current_car.nft_id)
                let correct, resultado= await this.handle_tx_confirmation(tx)
                if (resultado.status==0){
                    this.sending_nft=false
                    this.popToast("danger", "Transferencia fallida.")
                    return("error")
                }
                this.popToast("success", "NFT enviado correctamente")
                await this.getMyCars()
                this.sending_nft=false
                this.$bvModal.hide("sendNftModal")

            }catch(error){
                const serializedError = serializeError(error);
                this.handle_metamask_rpc_error(serializedError)
                this.sending_nft=false
            }
        },

        //gen_voucher_detailing
        async downloadVoucher(code, nft_id){
                    //console.log('code for voucher',code)
                    this.downloading=true
                    this.idVoucherDownload=nft_id
                    
                    axios.post('/api/landing/my_cars/gen_voucher', { 
                        "code": code,
                        "eth_address":this.my_eth_address,
                        "nft_id":nft_id,
                        "lang":this.$i18n.locale
                    })
                        .then((response) => {
                            this.status=response.data
                            if (this.status.resultado == "Ok") {
                                let b64 = response.data.Voucher
                                //console.log(b64)
                                let link = document.createElement('a');
                                link.innerHTML = 'Descargar boleto';
                                link.download = response.data.Filename;
                                link.href = 'data:application/octet-stream;base64,' + b64;
                                //document.body.appendChild(link);
                                link.click()                                
                                this.downloading=false
                                this.idVoucherDownload=""
                                //this.getMyCars()
                                this.popToast("success", this.status.mensaje, "Correcto")
                            }
                            else{
                                this.downloading=false
                                this.idVoucherDownload=""
                                this.popToast("danger", this.status.mensaje, "Error")
                            }
                            }).catch((error) => {
                                this.idVoucherDownload=""
                                this.downloading=false
                                console.log(error);
                        });
                },

        async downloadVoucherDetailing(code, nft_id){
                    //console.log('code for voucher',code)
                    this.downloading=true
                    this.idVoucherDownload=nft_id
                    
                    axios.post('/api/landing/my_cars/gen_voucher_detailing', { 
                        "code": code,
                        "eth_address":this.my_eth_address,
                        "nft_id":nft_id
                    })
                        .then((response) => {
                            this.status=response.data
                            if (this.status.resultado == "Ok") {
                                let b64 = response.data.Voucher
                                //console.log(b64)
                                let link = document.createElement('a');
                                link.innerHTML = 'Descargar boleto';
                                link.download = response.data.Filename;
                                link.href = 'data:application/octet-stream;base64,' + b64;
                                //document.body.appendChild(link);
                                link.click()                                
                                this.downloading=false
                                this.idVoucherDownload=""
                                //this.getMyCars()
                                this.popToast("success", this.status.mensaje, "Correcto")
                            }
                            else{
                                this.downloading=false
                                this.idVoucherDownload=""
                                this.popToast("danger", this.status.mensaje, "Error")
                            }
                            }).catch((error) => {
                                this.idVoucherDownload=""
                                this.downloading=false
                                console.log(error);
                        });
                },
        /*
        End metamask stuff
        */

        popToast(type, message) {
          this.$bvToast.toast(message, {
              variant: type,
              solid: true,
              noCloseButton: true,
              toaster: 'b-toaster-top-right',                
          })
      },    
    },
    mounted: async function(){
        this.loading=false
        console.log("AddressCarCollection", AddressCarCollection)
        
    },
    destroyed(){
        //console.log("removing listener")
        window.ethereum.removeAllListeners(['accountsChanged'])
        window.ethereum.removeAllListeners(['chainChanged'])
    }

  };
</script>
<style scoped>
    .title-main{
        color: #000;
        text-transform: uppercase;
        font-size: 5rem;
        text-align: center;
        font-weight:bold;
        margin:5rem 2rem 2rem 2rem;
    }
    .icon-car{
    width: 25px;
    font-size:22px;
}

</style>
