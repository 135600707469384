<template>
    <div id="legal">
        <EmptyNavBar/>
        <div class="row justify-content-center">
            <h1  class="title-main">AVISO LEGAL</h1>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-11 col-sm-12 align">
                <h4 class="title-second">INFORMACIÓN GENERAL</h4>
                <p class="h4">Titular del portal: Silatek, S.L.U.</p>
                <p class="h4">Dirección: Carrer Pobladó 037, Naus Cortes Num.2, Santa Coloma AD500, Andorra la Vella  </p>
                <p class="h4">Contacto:<span class="blue"> info@kartek.ad</span></p>
                <p class="h4">C.I.F. L713544Y </p>
                <p class="h4">Todos los derechos reservados.</p>
                <h4 class="title-second">CONDICIONES DE ACCESO Y UTILIZACIÓN DEL PORTAL</h4>
                <p class="h4">El usuario se compromete a utilizar el portal y los servicios de conformidad con la ley, el presente aviso legal, las condiciones particulares de ciertos servicios, así como la moral, buenas costumbres generalmente aceptadas y el orden público.</p>
                <p class="h4">Este sitio Web le es ofrecido a condición de que usted acepte íntegramente los términos, condiciones y comunicaciones contenidos en el presente aviso legal. El hecho de que usted use este sitio Web constituye por sí sólo la aceptación de los mismos. </p>
                <h4 class="title-second">LIMITACIONES DE USO</h4>            
                <p class="h4">Usted no está autorizado a modificar, copiar, distribuir, transmitir, divulgar, utilizar, reproducir, publicar, licenciar, ceder, vender ni crear trabajos derivados a partir de la información, el software, los productos o los servicios que pueda obtener de este sitio Web. El uso de este sitio Web con fines ilegales o no autorizados por los presentes términos, condiciones y comunicaciones queda terminantemente prohibido.</p>
                <p class="h4">No se asume ninguna responsabilidad del uso que los menores puedan hacer de la misma en casos de que los contenidos a que se acceda puedan herir la sensibilidad de los mismos. La plena responsabilidad en la determinación de los contenidos y servicios a los que acceden los menores de edad corresponde a los mayores a cuyo cargo se encuentran. Existen mecanismos, en particular programas informáticos de filtro y bloqueo, que permiten limitar los contenidos disponibles, siendo de especial utilidad para controlar y restringir los materiales a los que pueden acceder los menores.</p>
                <h4 class="title-second">CONEXIONES CON SITIOS DE TERCEROS</h4>
                <p class="h4">Este portal pone a disposición de los Usuarios dispositivos técnicos de enlace, directorios y otras herramientas como buscadores, que les permite acceder a sitios web pertenecientes y/o gestionados por terceros. La instalación de los mismos tiene por único objeto facilitar a los Usuarios el acceso a información, contenidos y servicios disponibles en Internet.</p>
                <p class="h4">Silatek, S.L.U.. no asume responsabilidad alguna por la información contenida en las páginas web de terceros a las que se pueda acceder por enlaces o buscadores desde nuestras páginas. Sin embargo, se compromete a retirar enlaces en caso de tener conocimiento de la existencia de contenidos ilícitos o que lesionen derechos de terceros.</p>
                <p class="h4">Se excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la presencia de virus o a la presencia de otros elementos lesivos en los servicios prestados por terceros a través del portal que puedan producir alteraciones en el sistema informático, documentos electrónicos o ficheros de los usuarios.</p>
                <h4 class="title-second">DERECHOS DE PROPIEDAD INTELECTUAL</h4>
                <p class="h4">El usuario reconoce y acepta que todos los derechos sobre el contenido de este sitio web pertenecen a Silatek, S.L.U. y que en consecuencia la modificación, adaptación, comunicación pública, distribución, venta, alquiler y cualquier otro derecho de propiedad intelectual realizado por el usuario sin consentimiento de Silatek, S.L.U. queda prohibido.</p>
                <p class="h4">Asimismo, Silatek, S.L.U. es titular de los derechos de propiedad industrial derivados de los elementos que integran su página web o, en cualquier caso, dispone de la correspondiente autorización para su utilización.</p>
                <p class="h4">En ningún caso, Silatek, S.L.U. será responsable de las posibles infracciones que en esta materia pudiera producir cualquier usuario del portal. </p>
                <h4 class="title-second">ACTUALIZACIÓN Y MODIFICACIÓN DE LA PÁGINA WEB</h4>
                <p class="h4">Silatek, S.L.U. se reserva el derecho a actualizar, modificar o eliminar la información de esta página web y su configuración sin preaviso. De igual forma podrá suspender su difusión total o parcialmente y modificar la estructura o contenido de la misma sin previo aviso.</p>
                <p class="h4">Para la resolución de problemas o preguntas relacionadas con este sitio Web, por favor póngase en contacto con Silatek, S.L.U., en la siguiente dirección de correo electrónico <span class="blue">info@kartek.ad </span>.</p>
            </div>
        </div>
        <Foot/>
    </div>

</template>

<script>
    import EmptyNavBar from '@/components/EmptyNavBar.vue'
    import Foot from '@/components/FooTer.vue'


    export default {
        name: "legal_form",
        components: { 
          EmptyNavBar,
          Foot
        }
    }
</script>