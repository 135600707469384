<template>
  <div id="car" v-cloak>
    <div v-if="loading==true" class="row justify-content-center">
        <img class="spinner-logo" src="../../public/logo-spinner.svg" alt="nav-logo" spin />
    </div> 
    <div v-else >
    <EmptyNavBar/>
    <ToTop/>    
          <div  class="d-none d-lg-block">         
            <!-- @click="openCarouselBig(carousel, image.img_name)"-->
            <swiper-container style="--swiper-navigation-color: #0064BF;" slides-per-view="1" navigation="true" zoom="true" keyboard="true" loop="true"  class="mySwiper"  thumbs-swiper=".mySwiper2">
                <swiper-slide v-for="image in carousel" :key="image.img_name">
                  <div  class="row justify-content-center">
                    <div class="swiper-zoom-container">
                      <v-lazy-image class="img-init-splide" alt="fotos del coche"
                        :src="image.url"
                        :src-placeholder="image.preview"
                      />
                    </div> 
                  </div> 
                </swiper-slide>
                      
            </swiper-container>
            <swiper-container class="thumbSwiper mySwiper2 mt-2" style="--swiper-navigation-color: #000;--swiper-navigation-size:25px"  navigation="true" loop="true"  space-between="5" :slides-per-view="8"  free-mode="true" watch-slides-progress="true">
              <swiper-slide v-for="image in carousel" :key="image.img_name">
                <div  class="row justify-content-center">
                  <v-lazy-image class="img-init-splide" alt="fotos del coche"
                    :src="image.url"
                    :src-placeholder="image.preview"
                  />
                </div>  
              </swiper-slide>
            </swiper-container>

            <!--<div id="main-carousel" class="carousel-init splide" aria-label="Carrusel de images del coche"
              :options="mainOptions"
              ref="main_carrousel"
            >
              <div class="splide__track">
                <ul class="splide__list">
                  <li class="splide__slide"  v-for="image in carousel" :key="image.img_name">
                    <div  class="row justify-content-center">
                      <v-lazy-image class="img-init-splide" alt="fotos del coche"
                        :src="image.url"
                        :src-placeholder="image.preview"
                      />
                     </div>                            
                  </li>                 
                </ul> 
              </div>
            </div>
            <div class=" row justify-content-center">
              <div id="thumbnail-carousel" class="splide" style="width:75%" :options="thumbsOptions" ref="thumbs_carrousel"  aria-label=" Carrusel con miniaturas. Seleccionar la miniatura para cambiar la imágen mostrada en el carrusel.">
                <div class="splide__track " >
                  <ul class="splide__list" >
                    <li class="splide__slide"  v-for="image in thumb_carousel" :key="image.img_name">
                      <img class="img-thumb" slot="image" :src="image.url" alt="foto de portada del coche" loading="eager">
                      <vue-load-image>          
                          <img class="img-thumb" slot="image" :src="image.url" alt="foto de portada del coche" loading="eager">
                          <div slot="preloader">
                            <div class="row justify-content-center">
                              <font-awesome-icon icon="circle-notch" class="mr-2" spin  />
                            </div>
                          </div>
                          <div slot="error">Error</div>                            
                        </vue-load-image>                 
                    </li>
                  </ul>
                </div>
              </div>
            </div>-->
          </div>
          <div class="d-lg-none" >
            <swiper-container  style="--swiper-navigation-color: #0064BF;" slides-per-view="1" navigation="true" zoom="true" keyboard="true" loop="true"  class="carousel-car-phone mySwiper"  thumbs-swiper=".mySwiper3">
                <swiper-slide v-for="image in carousel" :key="image.img_name">
                  <div  class="row justify-content-center">
                    <div class="swiper-zoom-container">
                      <v-lazy-image  alt="fotos del coche"
                        :src="image.url"
                        :src-placeholder="image.preview"
                      />
                    </div> 
                  </div> 
                </swiper-slide>
            </swiper-container>
            <swiper-container class="thumbSwiper mySwiper3" style="--swiper-navigation-color: #000;--swiper-navigation-size:25px"  navigation="true" loop="true"  space-between="5" :slides-per-view="4"  free-mode="true" watch-slides-progress="true">
              <swiper-slide v-for="image in carousel" :key="image.img_name">
                <div  class="row justify-content-center">
                  <v-lazy-image alt="fotos del coche"
                    :src="image.url"
                    :src-placeholder="image.preview"
                  />
                </div>  
              </swiper-slide>
            </swiper-container>



          <!--  <div id="carousel-car-phone" class="carousel-init splide" aria-label="Carrusel de images del coche"
              :options="mainOptions"
              ref="main_phone_carousel"
            >
              <div class="splide__track">
                <ul class="splide__list">
                  <li class="splide__slide"  v-for="image in carousel" :key="image.img_name">
                    <div  class="row justify-content-center">
                      <v-lazy-image class="img-car-phone" alt="fotos del coche"
                        :src="image.url"
                        :src-placeholder="image.preview"
                      />
                    </div>                            
                  </li>                 
                </ul>
              </div>
            </div>
           
            <div
              id="thumbnail-carousel2"
              class="splide"
              :options="thumbsOptions"
              ref="thumbs_phone_carrousel"
              aria-label=" Carrusel con miniaturas. Seleccionar la miniatura para cambiar la imágen mostrada en el carrusel."
            >
            
              <div class="splide__track ">
                <ul class="splide__list" >
                  <li class="splide__slide "  v-for="image in thumb_carousel" :key="image.img_name">
                    <vue-load-image>
                        <img slot="image" :src="image.url" class="img-thumb" alt="foto de portada del coche" loading="eager">                            
                        <div slot="preloader" >
                          <font-awesome-icon icon="circle-notch" class="mr-2" spin  />
                        </div>
                        <div slot="error">Error en carregar, recarregueu la pàgina</div>
                    </vue-load-image>              
                  </li>
                </ul>
              </div>
            </div>-->
      </div>
        <div class="row justify-content-center mb-4">
          <div class="col-lg-8 col-md-11 col-sm-12 text-center">
              <h1 class="title-car" id="stock">{{car_info.title[$i18n.locale]}}</h1>
              <h1 class="title-car blue" id="stock">{{car_info.price_string}}*</h1>
              <p class="h4">{{car_info.kilometers}} km</p>
          </div>
        </div>
        <div class="row justify-content-center  text-center mb-4">
              <p class="block-icon-car">
                  <font-awesome-icon v-if="car_info.fuel.es=='Gasolina' || car_info.fuel.es=='Híbrido' || car_info.fuel.es=='Gasoil'" icon="gas-pump" class="icon-car-big" />
                  <font-awesome-icon v-if="car_info.fuel.es=='Eléctrico' || car_info.fuel.es=='Híbrido'" icon="bolt" class="icon-car-big"/><br>
                  {{car_info.fuel[$i18n.locale]}}
              </p>
              <p class="block-icon-car">
                  <img src="../../public/gearbox.svg" class="icon-car-big" alt="gearbox"><br> {{car_info.gearbox[$i18n.locale]}}
              </p>
              <p class="block-icon-car" >
                  <img src="../../public/horsepower.svg" class="icon-car-big" alt="gearbox" style="padding-bottom:10px"><br> {{car_info.horsepower}} 
                  <span v-if="[$i18n.locale]=='en'">hp</span><span v-else-if="[$i18n.locale]=='fr'">up</span><span v-else>cv</span>
              </p>
              <p  v-b-popover.hover.top="'Año'" class="block-icon-car">
                <font-awesome-icon icon="calendar" class="icon-car-big" /><br>
                {{car_info.year}}
              </p>    
              <a class=" list-icon " href="#metamask" v-if="car_info.has_nft==true && [$i18n.locale]=='es'"  v-b-popover.hover.top="'Disponible para comprar con criptomonedas'">
                <img src="../../public/coins/meta.svg" class="nav-bitcoin2"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas">
                <!--<font-awesome-icon :icon="['fab', 'bitcoin']" class="nav-bitcoin2" />-->
              </a> 
              <a class=" list-icon " href="#metamask"  v-if="car_info.has_nft==true && [$i18n.locale]=='en'"  v-b-popover.hover.top="'Available to buy with cryptocurrencies'">
                <img src="../../public/coins/meta.svg" class="nav-bitcoin2"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas">
              </a>     
              <a class=" list-icon " href="#metamask" v-if="car_info.has_nft==true && [$i18n.locale]=='fr'"  v-b-popover.hover.top="'Disponible à l`achat avec des crypto-monnaies'">
                <img src="../../public/coins/meta.svg" class="nav-bitcoin2"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas">
              </a>
              <a class=" list-icon " href="#metamask"  v-if="car_info.has_nft==true && [$i18n.locale]=='cat'"  v-b-popover.hover.top="'Disponible per comprar amb criptomonedes'">
                <img src="../../public/coins/meta.svg" class="nav-bitcoin2"  target="_blank" rel="noopener" alt="seccion pagos con criptomonedas">
              </a>            
             <!-- <p  v-if="car_info.has_nft==true && [$i18n.locale]=='en'"  v-b-popover.hover.top="'Disponible para comprar con criptomonedas'">
                  <font-awesome-icon :icon="['fab', 'bitcoin']" class="nav-bitcoin2" />
              </p>             
              <p v-if="car_info.has_nft==true && [$i18n.locale]=='fr'"  v-b-popover.hover.top="'Disponible à l`achat avec des crypto-monnaies'">
                  <font-awesome-icon :icon="['fab', 'bitcoin']" class="nav-bitcoin2" />
              </p>  
              <p v-if="car_info.has_nft==true && [$i18n.locale]=='cat'"  v-b-popover.hover.top="'Disponible per comprar amb criptomonedes'">
                  <font-awesome-icon :icon="['fab', 'bitcoin']" class="nav-bitcoin2" />
              </p>   -->  
                                      
          </div>   
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-11 col-sm-12 align">
                <p class="h3" v-html=car_info.description[$i18n.locale]></p>
                <p class="h3">*{{ $t('price_valid')}}</p>
            </div>
          </div>

          <div class="row justify-content-center mt-4 mb-4" v-if="equip_with_img.length>0 || equip_without_img.length>0">
            <div class="col-lg-8 col-md-11 col-sm-12 jus">
              <h1 class="tecnic-title">{{ $t('Equipamiento')}}</h1>
            </div>           
          </div>
          <div class="row justify-content-center mt-4" v-if="equip_with_img.length>0">
              <div class="col-lg-3 col-md-8 col-sm-12 text-center mb-4" v-for="equip in equip_with_img" :key="equip.id_equip">
                <a class="card card-shadow black extra-card" @click="openDefaultModal(equip)">
                    <img class="extra-img" :src="equip.pictures[0].url" alt="detall de l'equipament de sèrie del cotxe" loading="lazy"> 
                    <div class="card-img-overlay overlay-extra extra-img">
                      <p class="overlay-text h2">{{equip.name[$i18n.locale]}}</p>            
                    </div>
                </a>
              </div>
          </div>
          <div class="row justify-content-center mt-4" v-if="equip_without_img.length>0">
            <div class="col-lg-8 col-md-12 col-sm-12" v-for="equip in equip_without_img" :key="equip.name.es">
                <ul class="ul-margin">
                  <li class="capi" v-if="equip.description[$i18n.locale]!=' '"><a class="h3" @click="openDefaultModal(equip)">{{equip.name[$i18n.locale]}}</a></li>                  
                  <li class="capi" v-else><p class="h3" >{{equip.name[$i18n.locale]}}</p></li>
                </ul>
            </div>
          </div>
          <b-modal id="defaultModal"  size="lg" hide-footer scrollable centered>
              <template #modal-header>
                  <a class="x" @click="$bvModal.hide('defaultModal')"><font-awesome-icon icon="times" /></a> 
                  <div class="col text-center p">
                      <p class="capi h3 bold m-0">{{current_equip.name[$i18n.locale]}}</p>
                  </div>                   
              </template>
              <div class="row justify-content-center mb-4" v-if="current_equip.pictures_mid[0].url!=undefined" >
                <div v-if="(current_equip.pictures_mid).length<2">
                  <vue-load-image>
                    <img  slot="image" class="extra-img" style="max-width:490px"  :src="current_equip.pictures_mid[0].url" alt="detall de l'equipament extra del cotxe" loading="lazy">
                    <img slot="preloader" class="card-img-spinner" style="max-width:490px" src="../../public/logo-spinner.svg"/>
                    <div slot="error">Error en carregar, recarregueu la pàgina</div>
                  </vue-load-image>
                </div>
                <b-carousel
                  v-else
                  :interval="6000"
                  controls
                  indicators
                  background="#fff"
                  >
                  <b-carousel-slide v-for="image in current_equip.pictures_mid" :key="image.img_name" style="max-width:75vh">
                      <template #img>
                        <vue-load-image>
                            <img slot="image" :src="image.url" style="max-width:490px" alt="foto extra" loading="lazy">
                            <img slot="preloader" class="card-img-spinner" style="max-width:490px" src="../../public/logo-spinner.svg"/>
                            <div slot="error">Error en carregar, recarregueu la pàgina</div>
                        </vue-load-image>
                     
                      </template>
                  </b-carousel-slide>           
                </b-carousel>    
              </div>       
              <div class="row justify-content-center">
                <p class="pl-2 pr-2 h4">{{current_equip.description[$i18n.locale]}}</p>
              </div>                      
          </b-modal>                      
         
          <div class="row justify-content-center mt-4 mb-4" v-if="extra_with_img.length>0 || extra_without_img.length>0">
            <div class="col-lg-8 col-md-11 col-sm-12 jus">
              <h1 class="tecnic-title">{{ $t('Extras')}}</h1>
            </div>              
          </div>
          <div class="row justify-content-center mt-4" v-if="extra_with_img.length>0">
              <div class="col-lg-8 col-md-12 col-sm-12">
                <div class="row justify-content-center mt-4">
                    <div class="col-lg-5 col-md-8 col-sm-12 text-center mb-4" v-for="extra in extra_with_img" :key="extra.id_equip">
                        <a class="card card-shadow black extra-card" @click="openExtraModal(extra)">
                            <img class="extra-img" :src="extra.pictures[0].url" alt="detall de l'equipament extra del cotxe" loading="lazy"> 
                            <div class="card-img-overlay overlay-extra extra-img">
                              <p class="h2 overlay-text">{{extra.name[$i18n.locale]}}</p>                       
                            </div>
                        </a>  
                    </div>
                </div>
              </div>
          </div>
          <div class="row justify-content-center mt-4" v-if="extra_without_img.length>0">
            <div class="col-lg-8 col-md-12 col-sm-12" v-for="extra in extra_without_img" :key="extra.name.es">
              <ul class="ul-margin">
                <li class="capi" v-if="extra.description[$i18n.locale]!=' '"><a class="h3" @click="openDefaultModal(extra)">{{extra.name[$i18n.locale]}}</a></li>                  
                <li class="capi" v-else><p class="h3" >{{extra.name[$i18n.locale]}}</p></li>
              </ul>
            </div>
          </div>
          <div class="row justify-content-center mt-4" v-if="extra_with_img.length>0 || extra_without_img.length>0">
              <div class="col-lg-8 col-md-12 col-sm-12 ml-2">
                <p>({{ $t('Disclaimer_extras')}})</p>
              </div>
          </div>

          <div class="row justify-content-center mt-4 mb-4" v-if="car_info.imgs_360.num_imgs>0">
            <div class="col-lg-8 col-md-11 col-sm-12">
              <h1 class="tecnic-title mt-4">{{ $t('Vista 360º del vehículo')}}</h1>
            </div>
              <div class="col-lg-8 col-md-6 col-sm-12 text-center">
                  
                  <div id="threesixty" style="display:none">
                    <vue-three-sixty ref="visor_360"             
                      :amount=car_info.imgs_360.num_imgs
                      :imagePath=car_info.imgs_360.path
                      :fileName=car_info.imgs_360.img_name
                      hotspots=[]
                      scrollImage
                    >
                    </vue-three-sixty>
                  </div>
                  <!--buttonClass="dark"-->
                  <div class="container2">
                      <button class="buttn" id="button-threesixty"  @click="showView360()">
                          <div class="button__line"></div>
                          <div class="button__line"></div>
                          <span class="button__text"><font-awesome-icon icon="eye" class="mr-3 d-none d-md-block" />{{ $t('Mostrar vista 360º del vehiculo')}}</span>                                
                      </button>
                  </div>
                 <!-- <button id="button-threesixty" class="bttn btn-primar" @click="showView360()"><font-awesome-icon icon="eye" class="mr-2" />{{ $t('Mostrar vista 360º del vehiculo')}}</button>-->
              </div>
            </div>
              <span v-if="car_info.has_nft==true" id="metamask" class="section-web">
                <div class="back-cripto">
                  <div class="row justify-content-center">
                    <div class="row justify-content-center">
                      <h1 class="title-car" style="margin-top:100px">{{ $t('Pasarela de pago con criptomonedas')}}</h1> 
                    </div>
                  </div>
                  <div class="row justify-content-center mt-4">
                    <div class="col-lg-8 col-md-11 col-sm-12 align">
                      <p class="h3">{{ $t('crypto_text_5')}} </p>
                        <p class="h3">{{ $t('text_pasarela')}}</p>
                      </div>
                      <div class="col-lg-8 col-md-11 col-sm-12 align ">
                        <b-collapse id="collapse-1" >
                          <p style="border:1px solid lightgrey; margin-top:20px"></p>
                          <p class="h3">{{ $t('crypto_text_2')}}</p>
                          <p class="h3">{{ $t('crypto_text_3')}} </p>
                          <p class="h3">{{ $t('crypto_text_4')}} </p>
                          <p class="h3">{{ $t('contact_text')}}</p>
                        </b-collapse>     
                        <a v-b-toggle.collapse-1  class="h4 blue">
                          <p class="text-center mt-4">{{ $t('Más información')}} <font-awesome-icon icon="chevron-down" class="ml-2"/><font-awesome-icon icon="chevron-up" class="ml-2"/></p>                                         
                        </a><!--{{ $t('Información de la pasarela de pagos con criptomonedas')}} -->
                        
                    </div>    
                  </div>    

                  <div v-if="metamask_connected==false  && car_info.has_nft==true" class="row justify-content-center" >
                    <div class="col-12 text-center">
                      <div class="container2">
                            <button class="buttn"  @click="connect_metamask()">
                                <div class="button__line"></div>
                                <div class="button__line"></div>
                                <span class="button__text orange"><img src="../../public/coins/meta.svg" class="coin-meta mr-2 d-none d-md-block"  target="_blank" rel="noopener">{{ $t('Conectar Metamask')}}</span>                                
                            </button>
                        </div>

                         <!-- <a class="bttn btn-btc font-big" @click="connect_metamask()">{{ $t('Conectar Metamask')}}</a> -->
                          <p class=" h5 mt-2">{{ $t('¿No tienes metamask?')}} <a href="https://metamask.io/download/" target="_blank" rel="noopener" alt="enllaç per descarregar metamask" class="blue">{{ $t('Descárgalo aquí')}}</a>. {{ $t('Sencillo y seguro')}}.  </p>
                          <a class="h5 grey" href="https://academy.binance.com/es/articles/how-to-add-fantom-to-metamask" target="_blank" rel="noopener" title="enllaç al tutorial de metamask" >{{ $t('Aprende como usarlo con este tutorial')}}</a>
                      </div>
                  </div>

                  <div v-else-if="metamask_connected==true && car_info.has_nft==true" style="margin:70px 0 60px 0">             
                    <div class="row justify-content-center">
                      <div class="col-lg-6 col-md-6 col-sm-12 text-center card card-shadow">   
                        <h1 class="pt-4">{{ $t('Formulario de compra')}}</h1>  
                        <div class="card-body">
                                
                          <div class="row justify-content-center" >
                            <div class="col-lg-6 col-md-6 col-sm-12 ">
                              <p class="crypto-form-label">{{ $t('Token')}}</p>
                              <v-select :options="tokens" label="name" v-model="selected_token" @input="checkInitialized(selected_token.address, selected_token.symbol)">
                                <template v-slot:selected-option="token">
                                <img :src="token.icon" class="mr-2" alt="" width="25px" height="25px">
                                  <!--<span :class="option.icon"></span>-->
                                  {{ token.name }} ({{ token.symbol }})
                                </template>     
                                <template v-slot:option="token">
                                <img :src="token.icon" class="mr-1" alt="" width="25px" height="25px">
                                  <!--<span :class="option.icon"></span>-->
                                  {{ token.name }} ({{ token.symbol }})
                                </template>                    
                              </v-select> 
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 ">
                              <p class="crypto-form-label">{{ $t('Precio vehículo')}} €</p>
                              <p class="h4">{{car_info.price_string}}</p>
                            </div>
                            <div v-if="selected_token==''" class="col-lg-12 col-md-6 col-sm-12 ">
                              <p class="h4 pt-4">{{ $t('Selecciona un token del desplegable')}}.</p>
                            </div>
                          </div>
                          <div v-if="initializing==true">
                              <div  class="row justify-content-center " style="margin-top:30px">
                                <font-awesome-icon icon="circle-notch" class="icon mr-2" spin  />
                              </div>
                          </div>
                          <div v-if="selected_token!='' && initializing==false">
                            <div  class="row justify-content-center mt-4" >
                              <div class="col-lg-6 col-md-6 col-sm-12 " >
                                <p class="crypto-form-label">{{ $t('Balance billetera')}}</p>
                                <p class="h4">{{selected_token.balance}} {{selected_token.symbol}}</p>
                              </div>                            
                              <div class="col-lg-6 col-md-6 col-sm-12 ">                     
                                <p class="crypto-form-label">{{ $t('Precio vehículo')}} {{selected_token.symbol}}</p>
                                <!--                                  <img :src="selected_token.icon" class="pl-2" alt="" width="40px" height="30px">-->
                                <div  class="row justify-content-center" >
                                  <p class="h4 car-price-token">{{selected_token.car_price}} </p>
                                  <p class="h4">{{selected_token.symbol}}</p>
                                </div> 
                              </div> 
                            </div>
                           
                            <div v-if="selected_token.collection_approved==false" class="row justify-content-center" >
                              <div class="container">
                                <button v-if="approving==true"  class="buttn">
                                    <div class="button__line"></div>
                                    <div class="button__line"></div>
                                    <span class="button__text">{{ $t('Aprobando')}}.. <font-awesome-icon icon="circle-notch" spin  class="ml-3 d-none d-md-block" /></span>
                                </button>
                                <button v-else @click="approveToken(1)" class="buttn">
                                    <div class="button__line"></div>
                                    <div class="button__line"></div>
                                    <span class="button__text green">{{ $t('Aprobar')}} <font-awesome-icon icon="check" class="ml-3 d-none d-md-block" /></span>
                                </button>
                            </div>
                              
                             <!-- <a v-if="approving==true" class="bttn btn-primar " >{{ $t('Aprobando')}}...</a> 
                              <a v-else @click="approveToken(1)" class="bttn btn-primar ">{{ $t('Aprobar')}}</a> -->
                            </div>    
                            <div v-else class="row justify-content-center">
                              <div class="container">
                                <button v-if="open_buying==true"  class="buttn">
                                    <div class="button__line"></div>
                                    <div class="button__line"></div>
                                    <span class="button__text">{{ $t('Preparando')}} <font-awesome-icon icon="circle-notch" spin  class="ml-3 d-none d-md-block" /></span>
                                </button>
                                <button v-else @click="preBuyCar()" class="buttn">
                                    <div class="button__line"></div>
                                    <div class="button__line"></div>
                                    <span class="button__text">{{ $t('Previsualizar transacción')}} <font-awesome-icon icon="check" class="ml-3 d-none d-md-block" /></span>
                                </button>
                            </div>
                              
                              <!--<a v-if="open_buying==true" class="bttn btn-primar"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Preparando...</a> 
                              <a v-else @click="preBuyCar()" class="bttn btn-primar">{{ $t('Previsualizar transacción')}}</a>                      -->
                            
                              <!--<a v-if="approving==true" class="bttn btn-grey ml-2" >{{ $t('Cancelar aprobacion')}}...</a> 
                              <a v-else @click="approveToken(0)" class="bttn btn-grey ml-2">{{ $t('Cancelar aprobacion')}}</a> -->
                            </div>
                          </div>   
                        </div>
                      </div>  
                    </div>
                    <b-modal id="modalBuy" size="xl" hide-footer scrollable centered>
                      <template #modal-header>
                          <a class="x" @click="$bvModal.hide('modalBuy')"><font-awesome-icon icon="times" /></a> 
                          <div class="col text-center">
                              <h1 class="bold m-0">{{ $t('Previsualización transferencia')}}</h1>                            
                          </div>                   
                      </template>
                      <div v-if="car_bought==false">
                        <div class="row justify-content-center">
                          <div class="col-lg-10 col-md-11 col-sm-12 text-center">
                            <p class="h3">{{ $t('El vehículo que vas a comprar es el')}} <span class="blue">{{car_info.title[$i18n.locale]}}</span> </p><!--con <span class="blue"> ID {{car_info.id}}.</span>-->
                            <!--<p class="h4">{{ $t('Asegúrate que es el correcto')}}.</p>-->
                            <p class="h3  mt-4 jus" v-if="selected_token.is_stable==true">{{ $t('Precio máximo a pagar')}}: <span class="blue">{{selected_token.max_spend_formatted}} {{selected_token.symbol}} </span></p> 
                            <p class="h4 mt-4 mb-4 align">{{ $t('variac_precio')}}.</p>                                                 
                            <p class="h4 mt-4 mb-4 align">{{ $t('recalcular_precio')}}</p>                                                 
                          </div>
                        </div>
                        <div v-if="selected_token.is_stable==false" class="row justify-content-center">
                      
                          <div class="col-lg-5 col-md-11 col-sm-12 ">
                            <p class="crypto-form-label" for="slippage">Slippage (%)</p>
                            <input class="form-input" type="number" v-model="slippage_allowed" id="slippage">
                          </div>
                          <div class="col-lg-5 col-md-11 col-sm-12 text-center mt-2">
                            <div class="container2">
                                <button v-if="updating_prices==true"  class="buttn" style="margin-top:50px">
                                    <div class="button__line"></div>
                                    <div class="button__line"></div>
                                    <span class="button__text">{{ $t('Recalculando')}} <font-awesome-icon icon="circle-notch" spin  class="ml-3 d-none d-md-block" /></span>
                                </button>
                                <button v-else @click="updateCarPrices()" class="buttn" style="margin-top:50px">
                                    <div class="button__line"></div>
                                    <div class="button__line"></div>
                                    <span class="button__text orange">{{ $t('Recalcular')}} <font-awesome-icon icon="coins" class="ml-3 d-none d-md-block" /></span>
                                </button>
                            </div>
                                                        
                           <!-- <a v-if="updating_prices==true" class="bttn btn-primar ml-2" style="margin-top:50px"><font-awesome-icon icon="circle-notch" class="mr-2" spin />{{ $t('Recalculando')}}...</a>
                            <a v-else class="bttn btn-primar ml-2"  @click="updateCarPrices()"  style="margin-top:50px">{{ $t('Recalcular precios')}}</a>-->
                          </div>
                          <div class="col-lg-10 col-md-11 col-sm-12 text-center mt-4">
                            <p class="h4">{{ $t('Vas a pagar')}} <span class="blue">{{selected_token.max_spend_formatted}} {{selected_token.symbol}} </span>{{ $t('para obtener al menos')}} <span class="blue">{{selected_token.usdc_price_formatted}}  USDC</span>. {{ $t('Cualquier USDC obtenido de más será devuelto a tu cuenta')}}.</p>                          
                          </div>
                        </div>                    
                        <div class="text-center mb-4">                      
                          <div class="container2">
                                <button v-if="buying==true"  class="buttn" style="margin-top:50px">
                                    <div class="button__line"></div>
                                    <div class="button__line"></div>
                                    <span class="button__text">{{ $t('Comprando')}} <font-awesome-icon icon="circle-notch" spin  class="ml-3 d-none d-md-block" /></span>
                                </button>
                                <button v-else @click="confirm_buy()" class="buttn" style="margin-top:50px">
                                    <div class="button__line"></div>
                                    <div class="button__line"></div>
                                    <span class="button__text green">{{ $t('Comprar')}} <font-awesome-icon icon="car" class="ml-3 d-none d-md-block" /></span>
                                </button>
                            </div>
                         
                         
                         <!-- <a v-if="buying==true"  class="bttn btn-primar"><font-awesome-icon icon="circle-notch" class="mr-2" spin  />{{ $t('Comprando')}}...</a>
                          <a v-else class="bttn btn-green ml-2"  @click="confirm_buy()">{{ $t('Comprar')}}</a>                        
                          <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('modalBuy')">{{ $t('Cerrar')}}</a>-->
                        </div> 
                      </div> 
                      <div v-else class="text-center">
                            <h1 class="green ">{{ $t('¡Enhorabuena por tu compra!')}}</h1>
                            <p class="h4">{{ $t('Has adquirido el con NFT del')}} <span class="blue">{{car_info.title[$i18n.locale]}}</span> </p>
                            <div class="container">
                                <router-link :to="{name: 'my_cars'}" class="buttn">
                                    <div class="button__line"></div>
                                    <div class="button__line"></div>
                                    <span class="button__text">{{ $t('Acceder a mis coches')}} <font-awesome-icon icon="arrow-right" class="ml-3 d-none d-md-block" /></span>
                                
                                </router-link>
                            </div>
                            
                            <!--<button class="bttn btn-grey mt-4 ml-2"  @click="goToMyCars()">{{ $t('Ir a mis coches')}} </button>                  -->
                      </div>
                    </b-modal> 

                  </div>
                  <div v-else class="row justify-content-center" >
                      <img class="spinner-cars" src="../../public/logo-spinner.svg" alt="cargando coches vendidos"  />
                      <div class="col-12 text-center">
                          <p class="h3">{{ $t('Cargando')}}...</p>
                      </div>
                  </div>     
                </div>
            </span>
            
            <div class="">
              <div class="row justify-content-center " >
                <div class="col-lg-8 col-md-11 col-sm-12 text-center mb-4">
                  <h1 class="tecnic-title" id="contact"> {{ $t('¿Te gusta lo que ves?')}}</h1>
                </div>
              </div>
              <div class="row justify-content-center mt-4">
                  <div class="col-lg-8 col-md-11 col-sm-12 text-center mb-4">
                      <p class="h3">{{ $t('contact_text_1')}}</p>
                  </div>
                </div>
                <div class="row justify-content-center mt-4" style="margin-bottom:60px">
                  <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                    <a title="Envíanos un mesaje por whatsapp" target="_blank" href="https://wa.me/376395375" ><font-awesome-icon :icon="['fab', 'whatsapp-square']" class="whatsapp" /></a>
                    <p class="h3">{{ $t('contact_text_2')}}</p>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                      <font-awesome-icon icon="envelope" class="mb-2 icon" />
                      <p class="h3">info@kartek.ad</p>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                      <font-awesome-icon icon="phone" class="mb-2 icon" />
                      <p class="h3">+376 395 375</p>
                  </div>
                  
              </div>
            </div>
            <Foot/>
        </div>
  </div>
  
</template>
<script>
  /* eslint-disable */
  import { ethers, utils, BigNumber} from 'ethers'
  import { serializeError } from "eth-rpc-errors";
  //import {CI360Viewer} from "js-cloudimage-360-view"
  //import resetPosition from '@/vue-360/dist/index.js'
  import axios from "axios"
  //import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
  //import ToTop from '@/components/ToTop.vue'
  const car_collection_abi=require('../contracts_abis/abi_car_collection.json');
  const erc_20_abi=require('../contracts_abis/abi_erc20.json');
  const price_oracle__abi=require('../contracts_abis/abi_price_oracles.json');
  const AddressCarCollection=process.env.VUE_APP_ADDRESS_CAR_COLLECTION
  const AddressPriceOracle=process.env.VUE_APP_ADDRESS_PRICE_ORACLE

  import _ from 'underscore'
  const contracts_addresses =require('../contracts_addresses.json')
  import EmptyNavBar from '@/components/EmptyNavBar.vue'
  import Foot from '@/components/FooTer.vue'
  import ToTop from '@/components/ToTop.vue'
  //import Splide from '@splidejs/splide';
  //import '@splidejs/vue-splide/dist/css';
  //import '@splidejs/vue-splide/dist/css/themescss/core';
  //import '@splidejs/splide/dist/css/themes/splide-default.min.css';

    export default {
      name: "car",
      components: { 
        EmptyNavBar,
        Foot,
        ToTop
      //Vue360
    }, 
    /*metaInfo: {
      script: [
        { src: 'https://cdn.scaleflex.it/plugins/js-cloudimage-360-view/latest/js-cloudimage-360-view.min.js', async: true, defer: true }
      ],
    },*/
    data() {
      return {
       /* mainOptions: {
          type      : 'loop',
          perPage   : 1,
          perMove   : 1,
          pagination: false,          
        },
        thumbsOptions:{
          type        : 'slide',
          rewind      : true,
          gap         : '1rem',
          pagination  : false,
          fixedWidth  : 110,
          fixedHeight : 70,
          cover       : true,
          focus       : 'center',
          isNavigation: true,
          updateOnMove: true,
        },*/
        updating_prices:false,
        has_listeners:false,
        loading:true,
        sending:false,
        connecting:false,
        metamask_connected:false,
        address_owner_end:'',
        address_owner_init:'',
        wallet_manager:undefined,
        connected:false,
        deploying:false,
        claiming:false,
        creating:false,
        created:false,
        carousel:[],
        thumb_carousel:[],
        carousel_modal:[],
        car_info:{"id":"", 
                  "brand":'',
                  "model":'', 
                  "year":'', 
                  "has_nft":false,
                  "price":'',
                  "price_string":'',
                  "title":'',
                  "description":'',
                  "imgs":{
                      "min_imgs":{
                        "front":[],
                        "others":[]
                    },
                    "mid_imgs":{
                        "front":[],
                        "others":[]
                    } 
                  },
                  "engine":'',
                  "horsepower":'',
                  "traction":{"es":'', "cat":'', "en":'', "fr":''},
                  "gearbox":{"es":'', "cat":'', "en":'', "fr":''},
                  "fuel":{"es":'', "cat":'', "en":'', "fr":''},
                  "def_equipment":[],
                  "extra_equip":[],
                  "nft_id":"",
                  "imgs_360":{"img_name":"" ,"num_imgs":0,"path":""},
                  "nft_info":{}
      },
      current_equip:{
        "description":{},
        "name":{},
        "id_equip":'',
        "pictures":[{"url":undefined}],
        "pictures_mid":[{"url":undefined}],
      },
      tokens:[{"name":""}],
      selected_token:'',
      loaded_contracts:{},//{address:{"contract":contract, "symbol":symbol}}
      load_360:false,
      approve:false,
      approving:false,
      buying:false,
      initializing:false,
      open_buying:false,
      equip_without_img:[],
      equip_with_img:[],
      extra_without_img:[],
      extra_with_img:[],
      car_bought:false,
      slippage_allowed:0.5,
      }
    },

    methods: {
      buildTokensList(){
        this.tokens=[]
        for (var i=0; i<this.variable_tokens.length; i++){
          this.tokens.push({"name":this.variable_tokens[i]["name"],
                            "symbol":this.variable_tokens[i]["symbol"],
                            "address":this.variable_tokens[i]["address"],
                            "type":this.variable_tokens[i]["type"],
                            "icon":this.variable_tokens[i]["icon"],
                            "is_stable":false
                            })
        }
        for (var i=0; i<this.stable_tokens.length; i++){
          this.tokens.push({"name":this.stable_tokens[i]["name"],
                            "symbol":this.stable_tokens[i]["symbol"],
                            "address":this.stable_tokens[i]["address"],
                            "type":this.stable_tokens[i]["type"],
                            "icon":this.stable_tokens[i]["icon"],
                            "is_stable":true
                            })
        }
        
        console.log("this.tokens", this.tokens)
      },

      update_current_token_balance(){
        if (this.selected_token!=""){
          //console.log("updating balance")
          this.checkInitialized(this.selected_token.address)
        }
      },

      async checkInitialized(address, symbol){
        this.initializing=true
        let vm=this

        if (this.loaded_contracts[address]==undefined){
          if (this.metamask_connected==true){
            if (address!="0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"){
              this.loaded_contracts[address]={
              "write":new ethers.Contract(address, erc_20_abi["abi"], vm.signer),
              "read_only":new ethers.Contract(address, erc_20_abi["abi"], vm.web3_read_provider)
              }
            }
            else{
                this.loaded_contracts[address]={
                  "write":undefined,
                  "read_only":undefined
                  }
              }

            }
          else{
            this.loaded_contracts[address]={
              "write":undefined,
              "read_only":new ethers.Contract(address, erc_20_abi["abi"], this.web3_read_provider)
              }
          }
        }
        let current_balance=BigNumber.from(0)
        let token_decimals=18
        let allowance=BigNumber.from(0)
        if (this.metamask_connected==true){
          //console.log("this.my_eth_address",this.my_eth_address, vm.loaded_contracts[address]["read_only"])
          if (address!="0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"){
            try{
              current_balance=await this.loaded_contracts[address]["read_only"].balanceOf(this.my_eth_address)
              token_decimals=await this.loaded_contracts[address]["read_only"].decimals()
              allowance=await this.loaded_contracts[address]["read_only"].allowance(this.my_eth_address, AddressCarCollection)
              this.selected_token.balance=ethers.utils.formatUnits(current_balance, token_decimals)
              this.selected_token.decimals=token_decimals
              
              }
            catch(error) {
                this.selected_token.balance=ethers.utils.formatUnits(current_balance, token_decimals)
                console.log("error", error);
              };
          }
          else{
            current_balance=await this.web3_read_provider.getBalance(this.my_eth_address)
            allowance=ethers.constants.MaxUint256
            this.selected_token.balance=ethers.utils.formatUnits(current_balance, token_decimals)
            this.selected_token.decimals=18
          }
        }
        //this.selected_token.price=await this.getCarPrice(address)
        this.selected_token.collection_approved=allowance
        //this.selected_token.contract=this.loaded_contracts[address]
        //console.log("loaded_contracts", this.loaded_contracts, address)
        //console.log("this.selected_token",this.selected_token)
        this.selected_token.car_price=await this.getCarPrice(address)
        if(this.selected_token.is_stable==false){
          //console.log("fetching aggregator quote for token", this.selected_token.type, this.selected_token.symbol)
            let result_quote = await axios.post('/api/aggregator/get_quote', {
                "token_in":this.selected_token.address,
                "calculated_price":this.selected_token.car_price, //allows 1% slippage
                "car_price_eur":this.car_info.nft_info.Price.toNumber(), //allows 1% slippage
                "slippage_allowed":this.slippage_allowed
              })
            //console.log("result_quote", result_quote)
            if (result_quote.data.resultado === "Error"){
                  this.popToast("danger", result.data.mensaje, "Error")
            }
            else{
              this.selected_token.min_slippage=result_quote.data.min_slippage
              if (this.selected_token.min_slippage>this.slippage_allowed){
                  this.slippage_allowed=1.5*(this.selected_token.min_slippage)

                }
              
              this.selected_token.required_in=BigNumber.from(result_quote.data.required_in)
              this.selected_token.required_in_formated=ethers.utils.formatUnits(result_quote.data.required_in, this.selected_token.decimals)
              this.selected_token.min_out_usdc=BigNumber.from(result_quote.data.min_out_usdc)
              this.selected_token.min_out_usdc_formatted=ethers.utils.formatUnits(result_quote.data.min_out_usdc, 6)
              let price_usdc=await this.price_oracles_contract.eur_to_usdc(this.car_info.nft_info.Price)
              //console.log("price_usdc",price_usdc.toString())
              this.selected_token.usdc_price_formatted=ethers.utils.formatUnits(price_usdc, 6)
              this.selected_token.usdc_price=price_usdc
            }
        }
        else{
          this.selected_token.min_slippage=undefined
          this.selected_token.required_in=undefined
          this.selected_token.required_in_formated=undefined
          this.selected_token.min_out_usdc=undefined
          this.selected_token.min_out_usdc_formatted=undefined
        }
        
        this.$forceUpdate();
        this.initializing=false
        //console.log("this.selected_token",this.selected_token)
      },
      

      async getCarPrice(address){
        if (this.selected_token.symbol=="DAI"){
          let price=await this.price_oracles_contract.eur_to_dai(this.car_info.nft_info.Price)
          price=ethers.utils.formatUnits(price, this.selected_token.decimals)
          //console.log("price", price)
          return(price)
        }
        else if (this.selected_token.symbol=="USDC"){
          let price=await this.price_oracles_contract.eur_to_usdc(this.car_info.nft_info.Price)
          price=ethers.utils.formatUnits(price, this.selected_token.decimals)
          //price=1/price
          return(price)
        }
        else if (this.selected_token.symbol=="MIM"){
          let price=await this.price_oracles_contract.eur_to_mim(this.car_info.nft_info.Price)
          price=ethers.utils.formatUnits(price, this.selected_token.decimals)
          //price=1/price
          return(price)
        }
        else if (this.selected_token.symbol=="USDT"){
          let price=await this.price_oracles_contract.eur_to_usdt(this.car_info.nft_info.Price)
          price=ethers.utils.formatUnits(price, this.selected_token.decimals)
          //price=1/price
          return(price)
        }
        else if (this.selected_token.symbol=="MDAI"){
          let price=await this.price_oracles_contract.eur_to_dai(this.car_info.nft_info.Price)
          price=ethers.utils.formatUnits(price, this.selected_token.decimals)
          //price=1/price
          return(price)
        }
        else if (this.selected_token.symbol=="MUSDC"){
          let price=await this.price_oracles_contract.eur_to_usdc(this.car_info.nft_info.Price)
          price=ethers.utils.formatUnits(price, this.selected_token.decimals)
          //price=1/price
          return(price)
        }
        else if (this.selected_token.symbol=="WBTC"){
          let price=await this.price_oracles_contract.eur_to_btc(this.car_info.nft_info.Price)
          price=ethers.utils.formatUnits(price, this.selected_token.decimals)
          //price=1/price
          return(price)
        }
        else if (this.selected_token.symbol=="WETH"){
          let price=await this.price_oracles_contract.eur_to_eth(this.car_info.nft_info.Price)
          price=ethers.utils.formatUnits(price, this.selected_token.decimals)
          //price=1/price
          return(price)
        }
        else if (this.selected_token.symbol=="FTM"){
          let price=await this.price_oracles_contract.eur_to_ftm(this.car_info.nft_info.Price)
          price=ethers.utils.formatUnits(price, this.selected_token.decimals)
          //price=1/price
          return(price)
        }
        else{
          return(0)
          
        }
        
      },
      

      async getCarInfo(){
        this.loading_cars=true
        this.carousel=[]
        this.thumb_carousel=[]
        axios.post('/api/landing/cars/get_info', {
          "car_id":this.car_id
        })
            .then(async (result) => {
                //console.log("result",result)
                if (result.data.resultado === "Ok"){
                    this.car_info=result.data.car_info
                    
                    console.log("car_info:",this.car_info)
                      if(this.car_info.imgs.mid_imgs.front.length>0){
                        console.log('tiene front')
                        this.carousel.push(this.car_info.imgs.mid_imgs.front[0])
                        this.thumb_carousel.push(this.car_info.imgs.min_imgs.front[0])
                        //console.log('add front')
                        for (let i = 0; i < (this.car_info.imgs.mid_imgs.others).length; i++) {
                          this.carousel.push(this.car_info.imgs.mid_imgs.others[i])
                          this.thumb_carousel.push(this.car_info.imgs.min_imgs.others[i])
                          //console.log((this.car_info.imgs.mid_imgs.others).length,'add other', i)
                        }
                      }else{
                        this.carousel=this.car_info.imgs.mid_imgs.others
                        this.thumb_carousel=this.car_info.imgs.min_imgs.others
                      }
                    console.log(this.carousel,'carousel',this.carousel.lenght)
                    this.formatEquip()
                    this.loading_cars=false
                    //await this.sleep(1000)
                    this.mount_carrousels()
                    this.loading=false
                }
                else if (result.data.resultado === "Error"){
                    this.popToast("danger", result.data.mensaje, "Error")
                    this.loading_cars=false
                }
            }).catch((error) => {
                console.log(error);
                this.loading_cars=false
            });
      },
      handle_account_change(){
            this.connect_metamask()
        },

      async handle_chain_change(chainId){
          if(chainId==0xfa){ //mainnet or fork  || chainId==0x7A69
              this.connect_metamask()
          }
          
          else{
            this.popToast("danger", `Please, change to Fantom network`)
            this.metamask_connected=false
            this.web3_read_provider=undefined
            this.web3_provider=undefined
            this.my_eth_address=undefined
          }
      },
      async setup(){
          //console.log(this.signer)
          this.load_read_only_ws_provider()
          //console.log("this.web3_read_provider", this.web3_read_provider)
          if (this.metamask_connected==true){
            this.car_collection_contract = new ethers.Contract(AddressCarCollection, car_collection_abi["abi"], this.signer)
            //console.log('contract',this.car_collection_contract)
            this.update_current_token_balance()
            if (this.car_info.has_nft==true && this.metamask_connected==true){
              this.car_info.nft_info=await this.car_collection_contract_read_only.Cars(this.car_info.nft_id)
              console.log("nft_info price",this.car_info.nft_info.Price)
              //this.car_info.nft_id.price_formated=this.car_info.nft_info.Price
              
            }
          }     
      },

      async handle_tx_confirmation(tx_object){
          let result=undefined
          
          try{
              //console.log("handling tx")
              result = await this.web3_read_provider.waitForTransaction(tx_object.hash)
              //console.log("bien")
              return(true, result)
          }
          catch(error){
              this.popToast("error", error)
              //console.log("mal")
              return(false, error)
          }
      },
      handle_metamask_rpc_error(serialized_error){
          console.log("serializedError",serialized_error );
          try{
            if (serialized_error.data!=undefined){
                this.popToast("danger", serialized_error.data.originalError.reason)
            }
            else{
                this.popToast("danger", serialized_error.message)
            }
          }
          catch(error){
              this.popToast("danger", "Error")
              //console.log("mal")
          }
      },
      async connect_metamask(){ 
          this.popToast("danger", `This function is not available currently due to Multichain latest events, to buy in crypto contact us directly`)
          //console.log("this.$gtag",this.$gtag)
          /*
          this.$gtag.event('connect_metamask', { method: 'ConnectMetamask' })
          this.connecting=true
          let vm=this
          try{
              if(window.ethereum) {
              //let accounts= await window.ethereum.enable();
                if (this.has_listeners==false){
                          //console.log("creating listener", window.ethereum.listenerCount('accountsChanged'))
                          window.ethereum.on('accountsChanged', function () {
                              vm.handle_account_change();
                          })
                          
                          window.ethereum.on('chainChanged', function (chainId) {
                              vm.handle_chain_change(chainId)
                          })
                          this.has_listeners=true
                      }
                
                
                let chainId= await window.ethereum.request({ method: 'eth_chainId' })
                console.log("chainID", chainId)
                if (chainId==0xfa){ //0xfa2 para testnet  || chainId==0x7A69
                  await window.ethereum.request({ method: 'eth_requestAccounts' });
                  this.web3_provider=await new ethers.providers.Web3Provider(window.ethereum)
                  this.signer=this.web3_provider.getSigner()
                  //console.log("signer", this.signer)
                  this.my_eth_address= await window.ethereum.selectedAddress
                  //console.log('address',this.my_eth_address)
                  this.metamask_connected=true
                  this.connecting=false
                  this.popToast("success", `Successfuly conected wallet`)
                  await this.setup()
                  
                }
                else{
                  this.popToast("danger", `Please, change to Fantom network`)
                }
                
              }
              else{
                this.metamask_connected=false
                this.connecting=false
                this.popToast("danger", `Please, install metamask`)
              }        
          }

          catch(error){
              console.log(error)
              this.popToast("danger", `Error connecting metamask: ${error.message}`)
              this.metamask_connected=false
              this.connecting=false


          }
          */
          //return(this.web3_provider)   
      },
      load_read_only_ws_provider: function(){
          this.web3_read_provider= new ethers.providers.JsonRpcProvider("https://rpcftm.kartek.ad")
          this.car_collection_contract_read_only = new ethers.Contract(AddressCarCollection, car_collection_abi["abi"], this.web3_read_provider); 
          this.price_oracles_contract = new ethers.Contract(AddressPriceOracle, price_oracle__abi["abi"], this.web3_read_provider); 
          //console.log("this.web3_read_provider",this.web3_read_provider)
      },

     
      sleep: function(milliseconds){
          return new Promise(resolve => setTimeout(resolve, milliseconds))
      },  

      openDefaultModal(equip){
        this.current_equip={}
        this.current_equip=equip
        this.$bvModal.show('defaultModal')
      },
      
      openExtraModal(extra){
        console.log('extra',extra)
        this.current_equip={}
        this.current_equip=extra
        this.$bvModal.show('defaultModal')
      },
      async showView360(){
        document.getElementById('threesixty').style.display = "block"
        document.getElementById('button-threesixty').style.display = "none"

        //this.load_360=true
        //await this.sleep(2000)
        this.$refs.visor_360.resetPosition()
      },

      async approveToken(amount){
        this.approving=true
        try{
          if (amount==1){
            amount=ethers.constants.MaxUint256
          }
          let result_Approve = await this.loaded_contracts[this.selected_token.address].write.approve(AddressCarCollection, amount)
          //console.log("miau", result_Approve)
          let correct, resultado= await this.handle_tx_confirmation(result_Approve)
          let allowance=await this.loaded_contracts[this.selected_token.address]["read_only"].allowance(this.my_eth_address, AddressCarCollection)
          //console.log("allowance", allowance)
          this.selected_token.collection_approved=allowance
          this.popToast("success", "Aprobado contrato para comprar vehículo con "+this.selected_token.symbol)
          //await result_deposit.wait(1)
          //await result_deposit.wait(1)
          this.approving=false

        }catch(error){
            const serializedError = serializeError(error);
            this.approving=false
            this.handle_metamask_rpc_error(serializedError)
            
        }
      },
      async getSwapData(){
        let result_swap_data = await axios.post('/api/aggregator/get_swap', {
                  "token_in":this.selected_token.address,
                  "amount_in":this.selected_token.required_in.toString(), 
                  "min_out_usdc":this.selected_token.usdc_price.toString(),
                  "slippage":this.slippage_allowed,
                })
          console.log("result_swap_data", result_swap_data)
         return(result_swap_data)
      },

      async confirm_buy(){
        this.buying=true
        try{
          let buy_tx=undefined
          if (this.selected_token.is_stable==true){
            buy_tx=await this.car_collection_contract.buyCar(this.car_info.nft_id, this.selected_token.address, ethers.constants.HashZero, 0, this.selected_token.max_spend)
          }
          else if (this.selected_token.is_stable==false){
            if (this.selected_token.type=="token"){
              let tx_data=this.selected_token.swap_data.data.tx_data.tx.data
              buy_tx=await this.car_collection_contract.buyCar(this.car_info.nft_id, this.selected_token.address, tx_data, this.selected_token.swap_data.data.amount_in, this.selected_token.max_spend, {"value": 0 })
            }
            else if (this.selected_token.type=="native"){
              let tx_data=this.selected_token.swap_data.data.tx_data.tx.data
              //console.log("this.selected_token.swap_data.data.tx_data.tx.value",this.selected_token.swap_data.data.tx_data.tx.value)
              buy_tx=await this.car_collection_contract.buyCar(this.car_info.nft_id, this.selected_token.address, tx_data, this.selected_token.swap_data.data.amount_in, this.selected_token.max_spend,{"value": this.selected_token.swap_data.data.tx_data.tx.value })
            }
            
          }
          let correct, resultado= await this.handle_tx_confirmation(buy_tx)
          if (resultado.status==0){
                this.buying=false
                this.popToast("danger", "Transacción fallida, inténtelo de nuevo, puede que el precio de mercado del token seleccionado haya variado y por ello no se reciban suficientes USDC para completar la compra. Pruebe aumentando el slippage tolerado.")
                return("error")
              }
          //console.log("correct, resultado",correct, resultado)
          let car_info=await this.car_collection_contract.Cars(this.car_info.nft_id)
          //console.log("new_car_info", car_info)
          this.popToast("success", "Coche comprado con id "+this.car_info.nft_id)
          this.car_bought=true
          
          //this.$bvModal.show('modalBuy')
          this.buying=false
          //this.$forceUpdate()
        }catch(error){
            const serializedError = serializeError(error);
            this.buying=false
            this.handle_metamask_rpc_error(serializedError)
            
        }

      },

      async updateCarPrices(){
        this.updating_prices=true
        try{
              this.selected_token.car_price=await this.getCarPrice(this.selected_token.address)
          if(this.selected_token.is_stable==false){
            //console.log("fetching aggregator quote for token", this.selected_token.type, this.selected_token.symbol)
              let result_quote = await axios.post('/api/aggregator/get_quote', {
                  "token_in":this.selected_token.address,
                  "calculated_price":this.selected_token.car_price, //allows 1% slippage
                  "car_price_eur":this.car_info.nft_info.Price.toNumber(), //allows 1% slippage
                  "slippage_allowed": this.slippage_allowed
                })
              //console.log("result_quote", result_quote)
              if (result_quote.data.resultado === "Error"){
                    this.popToast("danger", result.data.mensaje, "Error")
                    return("error")
              }
              else{
                this.selected_token.min_slippage=result_quote.data.min_slippage
                if (this.selected_token.min_slippage>this.slippage_allowed){
                  this.slippage_allowed=1.5*(this.selected_token.min_slippage)

                }
                //this.slippage_allowed=(result_quote.data.min_slippage)*2
                this.selected_token.required_in=BigNumber.from(result_quote.data.required_in)
                this.selected_token.required_in_formated=ethers.utils.formatUnits(result_quote.data.required_in, this.selected_token.decimals)
                this.selected_token.min_out_usdc=BigNumber.from(result_quote.data.min_out_usdc)
                this.selected_token.min_out_usdc_formatted=ethers.utils.formatUnits(result_quote.data.min_out_usdc, 6)
                let price_usdc=await this.price_oracles_contract.eur_to_usdc(this.car_info.nft_info.Price.toNumber())
                //console.log("price_usdc",price_usdc.toString())
                this.selected_token.usdc_price_formatted=ethers.utils.formatUnits(price_usdc, 6)
                this.selected_token.usdc_price=price_usdc
                let swap_data= await this.getSwapData()
                this.selected_token.swap_data=swap_data
                //console.log("updated swap_data", swap_data)
                this.selected_token.max_spend_formatted=ethers.utils.formatUnits(swap_data.data.amount_in,this.selected_token.decimals)
                this.selected_token.max_spend=BigNumber.from(swap_data.data.amount_in)
              }
          }
          else{
            this.selected_token.required_in=undefined
            this.selected_token.required_in_formated=undefined
            this.selected_token.min_out_usdc=undefined
            this.selected_token.min_out_usdc_formatted=undefined
            this.selected_token.max_spend_formatted=this.selected_token.car_price
            this.selected_token.max_spend=ethers.utils.parseUnits(this.selected_token.car_price, this.selected_token.decimals)
          }
          this.updating_prices=false
          this.$forceUpdate();
          return("Ok")

        }catch(error){
            this.updating_prices=false
            this.popToast("danger", "Error")
            this.$forceUpdate();
            return("Error")
        }
      

      },
      async preBuyCar(){
        this.open_buying=true
        
        try{
          if (this.selected_token.is_stable==true){
            this.selected_token.max_spend_formatted=this.selected_token.car_price
            this.selected_token.max_spend=ethers.utils.parseUnits(this.selected_token.car_price, this.selected_token.decimals)
            this.$bvModal.show('modalBuy')
            this.open_buying=false
          }
          else if (this.selected_token.is_stable==false){
            let swap_data= await this.getSwapData()
            if (swap_data.data.result=="Error"){
              await this.updateCarPrices()
              console.log("error getting swap data, updating prices")
              swap_data= await this.getSwapData()
              
            }
            this.selected_token.swap_data=swap_data
            //console.log("swap_data", swap_data)
            this.selected_token.max_spend_formatted=ethers.utils.formatUnits(swap_data.data.amount_in,this.selected_token.decimals)
            this.selected_token.max_spend=BigNumber.from(swap_data.data.amount_in)
            this.$bvModal.show('modalBuy')
            this.open_buying=false
            
          }          
          
        }
        catch(error){
            //console.log("miau", error)
            const serializedError = serializeError(error);
            this.open_buying=false
            this.handle_metamask_rpc_error(serializedError)
            
        }
      },
      formatEquip(){
        let i=0
        for(i = 0; i < (this.car_info.def_equipment).length; i++){
          //console.log(this.car_info.def_equipment[i].pictures)
          if((this.car_info.def_equipment[i].pictures[0].url)!=null){
            this.equip_with_img.push(this.car_info.def_equipment[i])
          }else{
            this.equip_without_img.push(this.car_info.def_equipment[i])
          }        
        }
        for(i = 0; i < (this.car_info.extra_equip).length; i++){
          //console.log(this.car_info.def_equipment[i].pictures)
          if((this.car_info.extra_equip[i].pictures[0].url)!=null){
            this.extra_with_img.push(this.car_info.extra_equip[i])
          }else{
            this.extra_without_img.push(this.car_info.extra_equip[i])
          }        
        }
        console.log('with', this.equip_with_img.length,'no',this.equip_without_img.length)
      },
    
     
      openBuyCar(){
        this.open_buying=true
        this.$bvModal.show('modalBuy')
        this.open_buying=false
      },
     /* openCarouselBig(carousel, img_name){
        this.carousel_modal=[]
        //console.log('images', images)
        //console.log('name', img_name)
        let key=''  
        let position=undefined       
        for (let i = 0; i < carousel.length; i++) {          
          key=carousel[i]['img_name']          
          if(key==img_name){
            position=i
            //this.carousel_modal.push(carousel[i])
            //console.log('key',key, position)
            break           
          }
        }               
        for (let a = position; a < carousel.length; a++) {     
          this.carousel_modal.push(carousel[a])
          //console.log('pos+',position,this.carousel_modal)
        }
        for (let x = 0; x < position; x++) {   
          this.carousel_modal.push(carousel[x])
          //console.log('pos-',position, this.carousel_modal)  
        }
        //console.log('carousel.modal',this.carousel_modal)
        this.$bvModal.show('carouselBig')
      },*/
      popToast(type, message) {
          this.$bvToast.toast(message, {
              variant: type,
              solid: true,
              noCloseButton: true,
              toaster: 'b-toaster-top-right',                
          })
      },    

      async mount_carrousels(){
        await  this.$nextTick()
        //console.log("todo listo", this.$refs.main_carrousel,this.$refs.main_phone_carousel)
        var main = new Splide( this.$refs.main_carrousel, {
          type      : 'fade',
          rewind    : true,
          pagination: false,
          arrows    : false,
        } );

        var main_phone = new Splide( this.$refs.main_phone_carousel, {
          type      : 'fade',
          rewind    : true,
          pagination: false,
          arrows    : false,
        } );
   

        var thumbnails = new Splide( this.$refs.thumbs_carrousel, {
          fixedWidth  : 100,
          fixedHeight : 70,
          gap         : 10,
          rewind      : true,
          pagination  : false,
          isNavigation: true,
          focus      : 'center',
          breakpoints : {
            600: {
              fixedWidth : 70,
              fixedHeight: 55,
            },
          },
        } );

        var thumbnails2 = new Splide( this.$refs.thumbs_phone_carrousel, {
          fixedWidth  : 100,
          fixedHeight : 70,
          gap         : 5,
          rewind      : true,
          pagination  : false,
          isNavigation: true,
          focus      : 'center',
          breakpoints : {
            600: {
              fixedWidth : 70,
              fixedHeight: 55,
            },
          },
        } );

        main.sync( thumbnails );
        main.mount();
        main_phone.sync( thumbnails2 );
        main_phone.mount();
        thumbnails.mount();
        thumbnails2.mount();

      }
    },
    mounted: async function(){
      let vm=this
      this.car_id=this.$route.params.car_id
      this.getCarInfo()
      this.stable_tokens=contracts_addresses.stable_tokens
      this.variable_tokens=contracts_addresses.tokens
      this.buildTokensList()
      
      //})
      /*await this.sleep(5000)
      const main=this.$refs.main
      const thumbs=this.$refs.thumbs
      //main.sync(thumbs)
      console.log("main_carrosulse", main, thumbs)*/
     

    },
    destroyed(){
        //console.log("removing listener")
        window.ethereum.removeAllListeners(['accountsChanged'])
        window.ethereum.removeAllListeners(['chainChanged'])
    }

  };
</script>
<style scoped>

.spinner{
    font-size:20rem;
    margin-top: 200px;
}

.title-price{
  color: #0064BF;
  text-transform: uppercase;
  font-size: 6rem;
  text-align: center;
  font-weight:bold;
  margin: 1rem 0;
}

.tecnic-title{
font-size:2.6rem!important;
margin-bottom: 20px;
font-weight: bold;
margin-top: 40px;
}

.tecnic-list{
font-size:2rem!important;
padding-left: 10px;
}

.ul-tecnic{
list-style: none;
}

.ul-tecnic li:before {
content: '✓';
padding-right: 20px;
}

.extra-img{
      width: 100%;
      min-height: 75%;
      display: block; /* remove extra space below image */
      object-fit: cover;
}
.overlay-extra{
background-color: rgba(0, 0, 0, 0.5);
overflow-x: auto;
}
.overlay-extra:hover{
background-color: rgba(0, 0, 0, 0.1); 
transition: 0.4s;

}

.overlay-text{
color: white;
margin-top: 7%;
}
.crypto-form-label{
  font-size:1.5rem;
  color:#0064BF;
}
.car-price-token{
  text-overflow: ellipsis; 
  max-width:180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.capi:first-letter{
  text-transform: capitalize
}
/*
.img-thumb{
  width: 100%;
  height: auto;
}

*/
.nav-bitcoin2{
    width: 60px!important;
    height: 60px!important;
    color:#F7931A;
    margin-left: 25px;
}

.mySwiper{
  margin-top:54px;
}
.thumbSwiper {
  width: 100%;
  height: auto;
}

.thumbSwiper swiper-slide {
  opacity: 0.4;
  width: 25%;
  height: auto;

}

.thumbSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.thumbSwiper img {
  width: 80%;
  height: 100px;
}

.mySwiper3 img {
  width: 80%;
  height: 50px;
}

/* Sidebar sizes when expanded and expanded */
@media only screen
and (max-device-width : 767px){
 
 /* .img-car-phone{
    height:35vh;
  }*/
}

@media only screen and (min-width: 767px) and (max-device-width : 992px) and (orientation : portrait) {
    .ul-margin{
      margin-left: 35px;
    }
    .mySwiper3 img {
      width: 80%;
      height: 100px;
    }
}
@media only screen and (min-width: 767px) and (max-device-width : 992px) and (orientation : landscape)  {
   /* .img-car-phone{
    height:100vh;
   
    }*/
    .ul-margin{
      margin-left: 35px;
    }
}
@media only screen and (min-width: 993px) and (max-device-width : 1300px) {
}

</style>

