import Vue from 'vue'
import App from './App.vue'
//import _ from 'underscore';
import router from './router'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Toasted from 'vue-toasted'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import i18n from './i18n'
import VueThreeSixty from 'vue-360'
import VueLoadImage from 'vue-load-image'

import 'vue-360/dist/css/style.css'
import axios from "axios";
import progressive from 'progressive-image/dist/vue'
import VLazyImage from "v-lazy-image"; 
import VueGtag from "vue-gtag";
import { register } from 'swiper/element/bundle';

register();

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

Vue.use(VueThreeSixty)
Vue.use(VueGtag, {
  config: { id: "G-LMNRKFPX2N" }
  }, router);

//Vue.use(SpinViewer)
//Vue.use(ethers)
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(progressive, {
  removePreview: true,
  scale: true
})
library.add(fas)
library.add(far)
library.add(fab)

//Vue.component('spin-viewer', SpinViewer)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vue-load-image', VueLoadImage)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-lazy-image', VLazyImage)


Vue.component('v-select', vSelect)

Vue.use(Toasted)

Vue.config.productionTip = false



new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')